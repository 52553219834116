import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import CancelPopup from "../../../../../Components/Reusable/CancelPopup";
import Select from "react-select";
import EditBulkPopup from "./EditBulkPopup";
import "./index.scss";
import { AcademicYearListApi } from "../../../../../Action/AcademicYear";
const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "studentName", label: "Student Name" },
  { id: "mealName", label: "Meal Name", click: true },
  { id: "mealService", label: "Meal Service" },
  { id: "status", label: "Status", stcolor: true },
  // { id: "isFreeMealOrder", label: "FreeMealOrder" },
  { id: "mealQty", label: "Reimbursable Meal Qty" },
  { id: "billAmount", label: "Transaction Amount" },
  // { id: "gst", label: "GST" },
  // { id: "discount", label: "Discount" },
  // { id: "serviceCharge", label: "Service Charge" },
  // { id: "totalAmount", label: "Total Amount" },
  { id: "servingLineName", label: "Serving Line" },
  { id: "cashierName", label: "Cashier" },
  // { id: "referenceNo", label: "Reference No" },
  { id: "orderDate", label: "Order Date" },
];

function TransactionLog() {
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [derror, setDerror] = useState();
  const [reportDetails, setReportDetails] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [showData, setShowData] = useState();
  const [schoolYearList, setSchoolYearList] = useState([]);

  function localDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getAcademicYear();
    // eslint-disable-next-line
  }, []);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let getLocalData = JSON.parse(localStorage?.getItem('allD'))

  useEffect(() => {
    if (showData) {
      getPayment({ ...showData, startDate: new Date(localDate(showData?.serDate)), endDate: new Date(localDate(showData?.serDate)) })
      setShowData()
    }
    //eslint-disable-next-line
  }, [showData])

  const getAcademicYear = async () => {
    let resp = await AcademicYearListApi();
    if (resp?.length > 0) {
      setSchoolYearList(resp);
    }
  }

  useEffect(() => {
    if (parentList?.length > 0) {
      if (getLocalData) {
        let dataOfCall = getLocalData;
        setSchoolSelect(dataOfCall?.school);
        setValue("schoolDistrictId", dataOfCall?.schoolDistrictId);
        setValue("startDate", localDate(dataOfCall?.serDate?.split("T")[0]));
        setValue("endDate", localDate(dataOfCall?.serDate?.split("T")[0]));
        setValue("mealService", dataOfCall?.mealService);
        setShowBtn(true);
        setDerror({ err: "" })
        setShowData(getLocalData)
        localStorage?.removeItem('allD')
      }
    }
    //eslint-disable-next-line
  }, [parentList, getLocalData])

  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolDistrictId,
                label: sd?.districtName,
              };
            });
            setParentList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = (id) => {
    setSchoolList([]);
    setSchoolSelect([]);
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const dlist = res.data.payload?.map((sd) => {
                return {
                  value: sd?.schoolId,
                  label: sd?.schoolName,
                };
              });
              let uslist = [{ value: "all", label: "Select All" }, ...dlist];

              setSchoolList(uslist?.length > 1 ? uslist : []);
            } else {
              setSchoolList([]);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const getPayment = (data) => {
    setPaymentList([]);
    let is_success = true;
    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }
    if (is_success) {
      setDerror({ err: "" });
      setOpenCircle(true);

      let Usdata = {
        schoolDistrictId: data?.schoolDistrictId
          ? data?.schoolDistrictId
          : null,
        schoolId: schoolSelect?.map((x) => x.value),
        startDate: data?.startDate ? data?.startDate : null,
        endDate: data?.endDate ? data?.endDate : null,
        userId: LogDetail?.userId,
        mealService: data?.mealService ? data?.mealService : null,
        academicYearId: data?.academicYearId
      };

      let distName = parentList?.filter(
        (x) => x.value === Usdata?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();
      let sdate = data?.startDate ? data?.startDate?.toISOString() : null;
      let edate = data?.endDate ? data?.endDate?.toISOString() : null;
      let reData = {
        distName: distName,
        schName: schName,
        startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
        endDate: edate ? convertDate(edate?.split("T")[0]) : null,
        mealService: data?.mealService,
      };
      setReportDetails(reData);
      Axios.post(`/api/MealOrder/GetMealOrderFilterDataForAdmin`, Usdata, {
        headers,
      })
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              let orData = res.data.payload?.map((x) => {
                let mealList = x?.mealTransactionlst
                  ?.map((ml, i) => ml?.mealName)
                  ?.toString();
                return {
                  ...x,
                  mealName:
                    mealList?.length > 25
                      ? mealList?.substring(0, 25) + "..."
                      : mealList,
                  mealList: mealList,
                  mealService: x?.mealService,
                  // billAmount: (Math.round(x?.billAmount * 100) / 100).toFixed(
                  //   2
                  // ),
                  billAmount: x?.billAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                  isFreeMealOrder: x?.isFreeMealOrder ? "Yes" : "No",
                  status: x?.status,
                  orderDate: x.orderDate
                    ? // convertDate(x?.orderDate?.split("T")[0]) +
                    //   " " +
                    new Date(x?.orderDate).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })
                    : "",
                  mealQty: x?.reimbursableMealQty?.toLocaleString(undefined, undefined),
                  servingLineId: x?.servingLineId,
                  studentServingLineRelationId: x?.studentServingLineRelationId,
                  Action: (
                    <>
                      {x?.studentName === "Bulk Entry" ? (
                        <EditBulkPopup
                          id={x?.mealOrderId}
                          callapi={handleGetPay}
                        />
                      ) : (
                        ""
                      )}
                      {x?.status === "Complete" &&
                        x?.studentName !== "Bulk Entry" ? (
                        <CancelPopup
                          delteIcon={false}
                          deletetext={"Void"}
                          title={"Cancel Student Meal"}
                          mtd={"post"}
                          callapi={handleGetPay}
                          text={x?.studentName + " order"}
                          url={`/api/MealOrder/VoidMealOrderData?mealOrderId=${x?.mealOrderId}&createdBy=${LogDetail?.userId}`}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ),
                };
              });
              setPaymentList(orData);
              setOpenCircle(false);
            } else {
              setPaymentList([]);
              setOpenCircle(false);
            }
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };

  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };

  const handleGetPay = () => {
    let sData = {
      schoolDistrictId: getValues("schoolDistrictId"),
      startDate: getValues("startDate"),
      endDate: getValues("endDate"),
      mealService: getValues("mealService"),
    };
    getPayment(sData);
  };

  const handleClose = () => {
    setShowBtn(false)
    localStorage.removeItem("allD");
    window.close();
  }

  return (
    <>
      {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="transaction_report-page">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <h4 className="all_title mb-4">Transaction Log </h4>
                <form onSubmit={handleSubmit(getPayment)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          District <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {parentList &&
                            parentList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          School <span className="red-color">*</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                              selected.length &&
                              selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                                ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                                : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {/* <select
                        className="form-select"
                        {...register("schoolId", {
                          required: "This field is required",
                        })}
                      >
                        <option value="">Select School </option>
                        {schoolList.map((item, i) => (
                          <option key={i} value={item?.value}>
                            {item?.label}
                          </option>
                        ))}
                      </select> */}
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Start Date <span className="red-color">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("startDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.startDate && (
                          <span role="alert" className="error_text">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          End Date <span className="red-color">*</span>
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          {...register("endDate", {
                            required: "This field is required",
                            valueAsDate: true,
                            validate: (val) => {
                              if (watch("startDate") > val) {
                                return "Your start date can not greater than from end date";
                              }
                            },
                          })}
                        />
                        {errors.endDate && (
                          <span role="alert" className="error_text">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Meal Service <span className="red-color">*</span>
                        </label>
                        <select
                          className="form-select"
                          {...register("mealService")}
                        >
                          <option value="All">All</option>
                          <option value="Breakfast">Breakfast</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School Year </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("academicYearId", {
                            valueAsNumber: true
                          })}
                        >
                          <option value="0">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.academicYearId && (
                          <span role="alert" className="error_text">
                            {errors.academicYearId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <>
                            {showBtn ?
                              <Button className="cancel_btn me-3" onClick={handleClose}>
                                Go Back
                              </Button>
                              : ""}
                            <Button className="theme_btn text-end" type="submit">
                              <img src={filter} className="me-1" alt="" />
                              Apply
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end mb-3 filter-img">
                  <Button className="theme_btn text-end">
                    <img src={filter} className="me-1" />
                    Apply
                  </Button>
                </div> */}
                </form>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={paymentList}
                  downloadFile={true}
                  reportDetails={reportDetails}
                  fileName="Transaction Log Report"
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default TransactionLog;
