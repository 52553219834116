import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "categoryCode", label: "Category Code" },
  { id: "categories", label: "Category Name" },
];
function ProductCategoryList() {
  const [categoyList, setCategoyList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    CategoryListData();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const CategoryListData = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/ProductCategory/GetAllProductCategoryData?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                categories: sd?.productCategoryName,
                categoryCode: sd?.productCategoryCode,
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.productCategoryId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Category"}
                      text={sd?.productCategoryName}
                      mtd={"post"}
                      url={`/api/ProductCategory/DeleteProductCategoryData?productCategoryId=${sd?.productCategoryId}`}
                    />
                  </>
                ),
              };
            });
            setCategoyList(SdData);
          }
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>

      <Suspense fallback={renderLoader()}>
        <section className="prd_category_list ">
          <div className="main_wrapper">
            <div className="product_box ">
              <div className="all_title ProductTitle">Category List</div>
              <Link to={"create"} className="theme_btn">
                <BsPlusSquareDotted fontSize={24} className="me-3" />
                Create
              </Link>
            </div>
            <ListTable
              headCells={productHead}
              action={true}
              ListData={categoyList}
            />
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ProductCategoryList;
