import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Modal } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { DeleteCallApi, GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useFieldArray, useForm } from "react-hook-form";
import uploadimg from "../../../../../../Assets/SvgImage/file-upload.svg";
import default_image from "../../../../../../Assets/default_profile.jpg";
import { MdDelete, MdEdit } from "react-icons/md";
import ProdPopUp from "./ProductPopup";
import { BsPlusSquareDotted } from "react-icons/bs";
import { Config } from "../../../../../../Utils/Config";
import ImgPopup from "../../../../../../Components/Reusable/ImgPopup";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function CreateFinanceVendors() {
  const { id, pId } = useParams();

  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [vendorCategory, setVendorCategory] = useState([]);
  const [files, setFiles] = useState();
  const [cmpDocs, setCmpDocs] = useState();
  const [prodEdit, setProdEdit] = useState([]);
  const [prodId, setProdId] = useState('');
  const [catList, setCatList] = useState([]);
  const [delPopup, setDelPopup] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [distList, setDistList] = useState([]);
  const [delId, setDelId] = useState('');

  const navigate = useNavigate();

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "financeContactSubs"
  })

  const { fields: allProduct, append: appendProduct, remove: removeProduct, update: updateProduct } = useFieldArray({
    control,
    name: "productsSubs"
  })

  const { fields: vendorAddress, append: appendAddress, remove: removeAddress } = useFieldArray({
    control,
    name: "vendorAddress"
  })

  useEffect(() => {
    if (id) {
      getEditedData(id);
    } else {
      append();
      appendAddress({});
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const districtList = LogDetail?.districtClasses?.map((x) => {
      return {
        value: x?.schoolDistrictId,
        label: x?.schoolDistrictName,
      }
    })
    setDistList(districtList ?? []);
    if (!id) {
      setTimeout(() => {
        setValue("schoolDistrictId", districtList[0]?.value);
        getVendorCategory("", districtList[0]?.value);
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);

  // get Edit data
  const getEditedData = async (id) => {
    setOpen(true);

    let sendData = {
      url: `/api/FinanceVendor/GetFinanceVendorDataById?vendorId=${id}`,
    };

    let res = await GetCallApi(sendData);
    setOpen(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let editsiteData = res?.data?.payload;
        getVendorCategory(editsiteData?.vendorCategoryId, editsiteData?.schoolDistrictId);
        setValue("schoolDistrictId", editsiteData?.schoolDistrictId);
        setValue("VendorId", editsiteData?.vendorId);
        setValue("VendorName", editsiteData?.vendorName);
        setValue("ContactName", editsiteData?.contactName);
        setValue("ContactEmail", editsiteData?.contactEmail);
        setValue("ContactPhone", editsiteData?.contactPhone);
        setValue("Address", editsiteData?.address);
        setValue("City", editsiteData?.city);
        setValue("State", editsiteData?.state);
        setValue("Country", editsiteData?.country);
        setValue("LogoImage", editsiteData?.logoImage);
        setValue("ZipCode", editsiteData?.zipCode);
        setValue("website", editsiteData?.webSite);
        setValue("CompanyDocumentURL", editsiteData?.companyDocumentUrl);
        setValue("AdditionalNotes", editsiteData?.additionalNotes);
        setValue("financeContactSubs", editsiteData?.contactInfo);
        if (editsiteData?.vendorAddress?.length > 0) {
          setValue("vendorAddress", editsiteData?.vendorAddress);
        } else {
          appendAddress({});
        }
        setValue("productsSubs", editsiteData?.productInfo?.map((x) => {
          return {
            ProductId: x?.productId,
            ProductName: x?.productName,
            CategoryId: x?.categoryId,
            Description: x?.description,
            Price: x?.price,
            Quantity: x?.quantity,
            ProductImageUrlShow: `${Config.API_HOST_URL_live}${x?.productImageUrl1}`,
            ProductImageUrl: `${Config.API_HOST_URL_live}${x?.productImageUrl1}`,
          }
        }));
        setValue("PaymentRemittanceAddress", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceAddress);
        setValue("PaymentRemittanceCity", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceCity);
        setValue("PaymentRemittanceContactEmail", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceContactEmail);
        setValue("PaymentRemittanceContactName", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceContactName);
        setValue("PaymentRemittanceContactPhone", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceContactPhone);
        setValue("PaymentRemittanceCountry", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceCountry);
        setValue("PaymentRemittanceState", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceState);
        setValue("PaymentRemittanceZipCode", editsiteData?.paymentRemittanceInformation[0]?.paymentRemittanceZipCode);
        setValue("specialInstructions", editsiteData?.paymentRemittanceInformation[0]?.specialInstructions);
      }
    }
  };

  // get vendor category
  const getVendorCategory = async (vendorCategoryId, scId) => {
    setVendorCategory([]);
    // setOpen(true);
    let seData = {
      url: `/api/FinanceVendorCategory/GetFinanceVendorCategorysData?active=${"true"}`,
      body: {
        schoolDistrictId: scId ? scId : "00000000-0000-0000-0000-000000000000",
        schoolId: []
      }
    };

    let resp = await PostCallApi(seData);
    // setOpen(false);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        setVendorCategory(resp?.data?.payload);
        if (vendorCategoryId) {
          setTimeout(() => {
            setValue("VendorCategoryId", vendorCategoryId);
          }, 1500);
        }
      } else {
        setOpen(false);
      }
    }
  };


  // submit handler
  const onSubmit = async (data) => {
    setOpen(true);

    let passData = {
      ...data,
      PaymentRemittanceInformationId: pId,
      CompanyDocumentURL: cmpDocs,
      LogoImage: files,
      CreatedBy: LogDetail?.userId,
      SchoolDistrictId: data?.schoolDistrictId,
    };

    let seData = {
      url: `/api/FinanceVendor/CreateFinanceVendor`,
      body: passData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
    let respo = await PostCallApi(seData);
    setOpen(false);
    if (respo?.status === 200) {
      if (respo.data.success) {
        let subsData = {
          vendorId: respo.data.payload?.vendorId,
          createdBy: LogDetail?.userId,
          schoolDistrictId: data?.schoolDistrictId,
          financeContactSubs: data?.financeContactSubs?.map((x) => {
            return {
              ...x,
            }
          }),
        }
        if (data?.financeContactSubs?.length > 0) {
          let multipleUrl = {
            url: `/api/FinanceVendor/SaveMultipleContactDetail`,
            body: subsData
          }
          await PostCallApi(multipleUrl);
        }

        let subsVendorData = {
          vendorId: respo.data.payload?.vendorId,
          createdBy: LogDetail?.userId,
          financeVenderAddressSubs: data?.vendorAddress?.map((x) => {
            return {
              ...x,
            }
          }),
        }
        if (data?.vendorAddress?.length > 0) {
          let addressUrl = {
            url: `/api/FinanceVendor/SaveMultipleAddressDetail`,
            body: subsVendorData
          }
          await PostCallApi(addressUrl);
        }
        allProduct && allProduct?.map(async (x) => {
          let seData = {
            ...x,
            VendorId: respo.data.payload?.vendorId,
            CreatedBy: LogDetail?.userId,
            SchoolDistrictId: data?.schoolDistrictId,
          }

          let apiUrl = {
            url: `/api/FinanceVendor/SaveProductDetail`,
            body: seData,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              Accept: "*/*",
            },
          }

          const resp = await PostCallApi(apiUrl)
          if (resp?.status === 200) {
            if (resp?.data?.success) {
            }
          }
        });
        setProdId('');
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        navigate(-1);
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo?.data?.message
            ? respo?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setOpen(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: respo?.data?.message
          ? respo?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };

  // handle file
  const handleFile = (e) => {
    if (e?.target?.files) {
      setValue("LogoImage", e?.target?.files[0]?.name);
      setFiles(e?.target?.files[0]);
    }
  };

  const handleCompanyUrl = (e) => {
    if (e?.target?.files) {
      setValue("CompanyDocumentURL", e?.target?.files[0]?.name);
      setCmpDocs(e?.target?.files[0]);
    }
  };

  const handledEditProd = (id, prod) => {
    setProdId(id);
    setProdEdit(prod);
    setOpenPopup(true);
  }

  const handleOpen = () => {
    setProdEdit([]);
    setOpenPopup(true);
  }

  const sendAllProduct = (data) => {
    appendProduct(data)
  }

  const handleRemove = async (data, ind) => {
    setDeleteData(data);
    setDelId(ind);
    setDelPopup(true);
  }

  const handleClose = () => {
    setDeleteData();
    setDelId('');
    setDelPopup(false);
  };

  const handledelete = async () => {
    if (deleteData?.ProductId) {
      setLoaderShow(true);
      let apiUrl = {
        url: `/api/FinanceVendor/DeleteVendorProduct?productId=${deleteData?.ProductId}`,
        body:{}
      }
      //const resp = await DeleteCallApi(apiUrl);
      const resp = await PostCallApi(apiUrl);
      setLoaderShow(false);
      if (resp?.status === 200) {
        if (resp?.data?.success) {
          removeProduct(delId);
          handleClose();
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: resp.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          handleClose();
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: resp?.data?.message
              ? resp?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      }
    } else {
      removeProduct(delId);
      handleClose();
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_vendor">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">
                {id ? "Edit" : "Create"} Finance Vendor
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h4>Vendor Information</h4>
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box p-3"
                >
                  <h5>General Information</h5>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          District <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register(`schoolDistrictId`, {
                            required: "This field is required",
                            onChange: (e) => getVendorCategory("", e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors?.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors?.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Vendor Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Vendor Name "
                          {...register("VendorName", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 255,
                              message: "Max 255 characters",
                            },
                          })}
                        />
                        {errors.VendorName && (
                          <span role="alert" className="error_text">
                            {errors.VendorName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="form-group">
                          <label>
                            Vendor Category <span className="red-color">*</span>{" "}
                          </label>
                          <select
                            name=""
                            className="form-select"
                            {...register("VendorCategoryId", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Vendor Category</option>
                            {vendorCategory?.map((item, i) => (
                              <option value={item.vendorCategoryId} key={i}>
                                {item.categoryName}
                              </option>
                            ))}
                          </select>
                          {errors.VendorCategoryId && (
                            <span role="alert" className="error_text">
                              {errors.VendorCategoryId.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Vendor Logo</label>
                        <div className="upload_vendor_logo">
                          <input
                            type="text"
                            className="form-control upload_box"
                            placeholder="No file Chosen"
                            readOnly
                            {...register("LogoImage", {
                              // required: "This field is required",
                            })}
                          />
                          <input
                            type="file"
                            id="vendor_logo"
                            accept="image/*"
                            className="form-control"
                            style={{ display: "none" }}
                            onChange={handleFile}
                          />
                          <label
                            htmlFor="vendor_logo"
                            className="upload_img_label mb-0 "
                          >
                            <img src={uploadimg} className="img-fluid" alt="" />
                          </label>
                        </div>
                        {/* {errors.LogoImage && (
                          <span role="alert" className="error_text">
                            {errors.LogoImage.message}
                          </span>
                        )} */}
                      </div>
                    </div>
                  </div>

                  <h5 className="mt-4">Contact Information</h5>
                  <div className="row">
                    {fields?.map((_, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={`row ${fields?.length - 1 === index
                              ? "mt-4"
                              : "border-bottom mt-4"
                              }`}
                          >
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Contact Name <span className="red-color">*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contact Name "
                                  {...register(`financeContactSubs.${index}.contactName`, {
                                    required: "This field is required",
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message: "Starting and Ending Space not allowed",
                                    },
                                    maxLength: {
                                      value: 250,
                                      message: "Max 250 characters",
                                    },
                                  })}
                                />
                                {errors?.financeContactSubs?.[index]?.contactName && (
                                  <span role="alert" className="error_text">
                                    {errors.financeContactSubs?.[index]?.contactName.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Contact Email <span className="red-color">*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contact Email"
                                  {...register(`financeContactSubs.${index}.contactEmail`, {
                                    required: "This field is required",
                                    pattern: {
                                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                      message: "Invalid email address",
                                    },
                                    maxLength: {
                                      value: 250,
                                      message: "Max 250 characters",
                                    },
                                  })}
                                />
                                {errors?.financeContactSubs?.[index]?.contactEmail && (
                                  <span role="alert" className="error_text">
                                    {errors.financeContactSubs?.[index]?.contactEmail.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Contact Phone <span className="red-color">*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contact Phone"
                                  {...register(`financeContactSubs.${index}.contactPhone`, {
                                    required: "This field is required",
                                    pattern: {
                                      value:
                                        /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                      message: "Enter valid phone number",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message: "Max 50 characters",
                                    },
                                  })}
                                />
                                {errors.financeContactSubs?.[index]?.contactPhone && (
                                  <span role="alert" className="error_text">
                                    {errors.financeContactSubs?.[index]?.contactPhone.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              {index === 0 ? (
                                <>
                                  <Button
                                    className="theme_btn"
                                    onClick={() => append({})}
                                    style={{ marginTop: "40px" }}
                                  >
                                    Add
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="delete_btn"
                                    style={{ marginTop: "40px" }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>

                  <h5 className="mt-4">Vendor Address</h5>
                  <div className="row">
                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address "
                          {...register("Address", {
                            // required: "This field is required",
                            maxLength: {
                              value: 255,
                              message: "Max 255 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.Address && (
                          <span role="alert" className="error_text">
                            {errors.Address.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="City "
                          {...register("City", {
                            // required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max 100 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.City && (
                          <span role="alert" className="error_text">
                            {errors.City.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="State "
                          {...register("State", {
                            // required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max 100 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.State && (
                          <span role="alert" className="error_text">
                            {errors.State.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          ZipCode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="ZipCode "
                          {...register("ZipCode", {
                            // required: "This field is required",
                            maxLength: {
                              value: 20,
                              message: "Max 20 characters",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Only numeric characters are allowed",
                            },
                          })}
                        />
                        {errors.ZipCode && (
                          <span role="alert" className="error_text">
                            {errors.ZipCode.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Country
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Country "
                          {...register("Country", {
                            // required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max 100 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.Country && (
                          <span role="alert" className="error_text">
                            {errors.Country.message}
                          </span>
                        )}
                      </div>
                    </div> */}

                    {vendorAddress && vendorAddress?.map((_, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={`row ${vendorAddress?.length - 1 === index
                            ? "mt-4"
                            : "border-bottom mt-4"
                            }`}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  Address
                                  <span className="red-color"> *</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address "
                                  {...register(`vendorAddress.${index}.address`, {
                                    required: "This field is required",
                                    maxLength: {
                                      value: 255,
                                      message: "Max 255 characters",
                                    },
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message: "Starting and Ending Space not allowed",
                                    },
                                  })}
                                />
                                {errors?.vendorAddress?.[index]?.address && (
                                  <span role="alert" className="error_text">
                                    {errors?.vendorAddress?.[index]?.address.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  City
                                  <span className="red-color"> *</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City "
                                  {...register(`vendorAddress.${index}.city`, {
                                    required: "This field is required",
                                    maxLength: {
                                      value: 100,
                                      message: "Max 100 characters",
                                    },
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message: "Starting and Ending Space not allowed",
                                    },
                                  })}
                                />
                                {errors?.vendorAddress?.[index]?.city && (
                                  <span role="alert" className="error_text">
                                    {errors?.vendorAddress?.[index]?.city.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  State
                                  <span className="red-color"> *</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="State "
                                  {...register(`vendorAddress.${index}.state`, {
                                    required: "This field is required",
                                    maxLength: {
                                      value: 100,
                                      message: "Max 100 characters",
                                    },
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message: "Starting and Ending Space not allowed",
                                    },
                                  })}
                                />
                                {errors?.vendorAddress?.[index]?.state && (
                                  <span role="alert" className="error_text">
                                    {errors?.vendorAddress?.[index]?.state.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  ZipCode
                                  <span className="red-color"> *</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="ZipCode "
                                  {...register(`vendorAddress.${index}.zipCode`, {
                                    required: "This field is required",
                                    maxLength: {
                                      value: 20,
                                      message: "Max 20 characters",
                                    },
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: "Only numeric characters are allowed",
                                    },
                                  })}
                                />
                                {errors?.vendorAddress?.[index]?.zipCode && (
                                  <span role="alert" className="error_text">
                                    {errors?.vendorAddress?.[index]?.zipCode.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>
                                  Country
                                  <span className="red-color"> *</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Country "
                                  {...register(`vendorAddress.${index}.country`, {
                                    required: "This field is required",
                                    maxLength: {
                                      value: 100,
                                      message: "Max 100 characters",
                                    },
                                    pattern: {
                                      value: /^[^\s]+(\s+[^\s]+)*$/,
                                      message: "Starting and Ending Space not allowed",
                                    },
                                  })}
                                />
                                {errors?.vendorAddress?.[index]?.country && (
                                  <span role="alert" className="error_text">
                                    {errors?.vendorAddress?.[index]?.country.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              {index === 0 ? (
                                <>
                                  <Button
                                    className="theme_btn"
                                    onClick={() => appendAddress({})}
                                    style={{ marginTop: "40px" }}
                                  >
                                    Add
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    type="button"
                                    onClick={() => removeAddress(index)}
                                    className="delete_btn"
                                    style={{ marginTop: "40px" }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Website
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Website "
                          {...register("website", {
                            // required: "This field is required",
                            maxLength: {
                              value: 550,
                              message: "Max 550 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.website && (
                          <span role="alert" className="error_text">
                            {errors.website.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Company Documents</label>
                        <div className="upload_vendor_logo">
                          <input
                            type="text"
                            className="form-control upload_box"
                            placeholder="No file Chosen"
                            readOnly
                            {...register("CompanyDocumentURL", {
                              // required: "This field is required",
                            })}
                          />
                          <input
                            type="file"
                            id="cmp_docs"
                            accept=".jpg, .jpeg, .png, .pdf, .ppt, .xls, .xlsx"
                            className="form-control"
                            style={{ display: "none" }}
                            onChange={handleCompanyUrl}
                          />
                          <label
                            htmlFor="cmp_docs"
                            className="upload_img_label mb-0 "
                          >
                            <img src={uploadimg} className="img-fluid" alt="" />
                          </label>
                        </div>
                        {errors.CompanyDocumentURL && (
                          <span role="alert" className="error_text">
                            {errors.CompanyDocumentURL.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group">
                        <label>
                          Additional Notes
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <textarea
                          rows={2}
                          className="form-control"
                          placeholder="Additional Notes"
                          {...register("AdditionalNotes", {
                            // required: "This field is required",
                            // maxLength: {
                            //   value: 255,
                            //   message:
                            //     "Description cannot exceed 255 characters",
                            // },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.AdditionalNotes && (
                          <span role="alert" className="error_text">
                            {errors.AdditionalNotes.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <h5 className="mt-4">Payment Remittance Information</h5>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance Contact Name{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Payment Remittance Contact Name "
                          {...register("PaymentRemittanceContactName", {
                            // required: "This field is required",
                            maxLength: {
                              value: 255,
                              message: "Max 255 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceContactName && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceContactName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance Contact Email{" "}
                          <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Payment Remittance Contact Email"
                          {...register("PaymentRemittanceContactEmail", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceContactEmail && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceContactEmail.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance Contact Phone{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Payment Remittance Contact Phone"
                          {...register("PaymentRemittanceContactPhone", {
                            // required: "This field is required",
                            maxLength: {
                              value: 50,
                              message: "Max 50 characters",
                            },
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceContactPhone && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceContactPhone.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance Address{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" Payment Remittance Address "
                          {...register("PaymentRemittanceAddress", {
                            // required: "This field is required",
                            maxLength: {
                              value: 255,
                              message: "Max 255 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceAddress && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceAddress.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance City{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Payment Remittance City "
                          {...register("PaymentRemittanceCity", {
                            // required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max 100 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceCity && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceCity.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance State{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Payment Remittance State "
                          {...register("PaymentRemittanceState", {
                            // required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max 100 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceState && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceState.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance ZipCode{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Payment Remittance ZipCode"
                          {...register("PaymentRemittanceZipCode", {
                            // required: "This field is required",
                            maxLength: {
                              value: 20,
                              message: "Max 20 characters",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Only numeric characters are allowed",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceZipCode && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceZipCode.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Payment Remittance Country{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Payment Remittance Country "
                          {...register("PaymentRemittanceCountry", {
                            // required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max 100 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.PaymentRemittanceCountry && (
                          <span role="alert" className="error_text">
                            {errors.PaymentRemittanceCountry.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Special Instructions{" "}
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder="Special Instructions"
                          {...register("specialInstructions", {
                            // required: "This field is required",
                            maxLength: {
                              value: 255,
                              message:
                                "Description cannot exceed 255 characters",
                            },
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.specialInstructions && (
                          <span role="alert" className="error_text">
                            {errors.specialInstructions.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-flex justify-content-between align-items-center">
                    <h5>Products</h5>
                    <Button onClick={handleOpen} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Button>
                  </div>
                  <div className="row table-responsive p-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Product Category</th>
                          <th>Product Description</th>
                          <th>Quantity</th>
                          <th>Unit Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allProduct?.length > 0 ? (
                          allProduct?.map((x, ind) => {
                            return (
                              <tr key={ind}>
                                <td>
                                  <ImgPopup img={
                                    x?.ProductImageUrlShow
                                      ? x?.ProductImageUrlShow
                                      : default_image} />
                                  {/* <img
                                    src={x?.ProductImageUrlShow}
                                    className="img-fluid"
                                    width="50px"
                                    height="40px"
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = default_image;
                                    }}
                                  /> */}
                                </td>
                                <td>{x?.ProductName}</td>
                                <td>{catList?.filter((c) => c?.catId === x?.CategoryId)[0]?.catName}</td>
                                <td>{x?.Description}</td>
                                <td>{x?.Quantity}</td>
                                <td>
                                  {parseFloat(x?.Price)?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                </td>
                                <td>
                                  <>
                                    <Button
                                      type="button"
                                      className="btn_edit"
                                      onClick={() => handledEditProd(ind, x)}
                                      style={{ color: "#80c242", background: "transparent", minWidth: "max-content" }}
                                    >
                                      <MdEdit fontSize={24} />
                                    </Button>
                                    <Button
                                      type="button"
                                      className="btn_dlt"
                                      onClick={() => handleRemove(x, ind)}
                                      style={{ color: "#80c242", background: "transparent", minWidth: "max-content" }}
                                    >
                                      <MdDelete className="icon" fontSize={24} />
                                    </Button>
                                  </>
                                </td>
                              </tr>
                            )
                          }
                          )
                        ) :
                          <tr className="all_list">
                            <td className="er_nodata m-0 p-5" colSpan={7}>
                              No Data Found
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-12 text-end">
                    <Button type="submit" className="theme_btn me-2">
                      Submit
                    </Button>
                    <Button className="cancel_btn" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <ProdPopUp catList={catList} setCatList={setCatList} prodEdit={prodEdit} prodId={prodId} openPopup={openPopup} setOpenPopup={setOpenPopup} appendProduct={appendProduct} setProdId={setProdId} sendAllProduct={sendAllProduct} updateProduct={updateProduct} />
      </Suspense>
      {delPopup && (
        <>
          <Suspense fallback={renderLoader()}>
            <Modal
              open={delPopup}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <div className="popup_delete_detail_box">
                  <h6>Delete Product</h6>
                  <div className="popup_delete_detail">
                    <p className="delete_text">
                      Are you sure you want to Delete
                      <span> {deleteData?.ProductName} ?</span>
                    </p>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="popup_btn delete"
                        onClick={handledelete}
                        disabled={loaderShow}
                      >
                        {
                          loaderShow ?
                            <CircularProgress sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "32px !important",
                              width: "32px !important",
                            }} />
                            :
                            "Delete"
                        }
                      </Button>
                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </Suspense>
        </>
      )}
    </>
  );
}

export default CreateFinanceVendors;
