import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useForm } from "react-hook-form";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const districtManagementHead = [
  { id: "depositSlipNumber", label: "DepositSlip Number " },
  { id: "bankDepositDate", label: "BankDeposit Date" },
  { id: "totalBankDepositAmount", label: "TotalBankDeposit Amount" },
  { id: "depositNotes", label: "Deposit Notes" },
];
function BankDeposit() {
  const [districtManagementData, setDistrictManagementData] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [distList, setDistList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);
  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            ...sd,
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getSchoolList = async (id) => {
    setSchoolList([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              ...sd,
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          setSchoolList(dlist?.length > 0 ? dlist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };

  useEffect(() => {
    if (getValues("schoolDistrictId")) {
      let se = {
        schoolDistrictId: getValues("schoolDistrictId"),
        schoolId: getValues("schoolId"),
        startDate: getValues("startDate"),
        endDate: getValues("endDate"),
      };
      GetBankDepositList(se);
    }
    // eslint-disable-next-line
  }, [deleteInfo]);

  const GetBankDepositList = async (data) => {
    setOpenCircle(true);
    let sendData = {
      ...data,
      userId: LogDetail?.userId,
    };
    let seData = {
      url: `/api/BankDeposit/GetAllBankDepositData`,
      body: sendData,
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        const SdData = res.data.payload?.map((sd, i) => {
          return {
            ...sd,
            ...sd.bd,
            bankDepositDate: sd?.bd?.bankDepositDate
              ? new Date(sd?.bd?.bankDepositDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              : "",
            totalBankDepositAmount: sd?.bd?.totalBankDepositAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`edit/${sd?.bd?.bankDepositId}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
                <DeletePopup
                  title={"Delete Bank Deposit"}
                  text={`DepositSlip Number: ${sd?.bd?.depositSlipNumber}`}
                  mtd={"post"}
                  url={`/api/BankDeposit/DeleteBankDepositData?bankDepositId=${sd?.bd?.bankDepositId}`}
                />
              </>
            ),
          };
        });
        setDistrictManagementData(SdData);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="bankdeposit_listpage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="district_hed ">
                  <div className="all_title title">Bank Deposit</div>

                  <div className="d-flex">
                    <Link to={"create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>

                <form onSubmit={handleSubmit(GetBankDepositList)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School </option>
                          {schoolList.map((item, i) => (
                            <option key={i} value={item?.value}>
                              {item?.label}
                            </option>
                          ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Start Date <span className="red-color">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("startDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.startDate && (
                          <span role="alert" className="error_text">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          End Date <span className="red-color">*</span>
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          {...register("endDate", {
                            required: "This field is required",
                            valueAsDate: true,
                            validate: (val) => {
                              if (watch("startDate") > val) {
                                return "Your start date can not greater than from end date";
                              }
                            },
                          })}
                        />
                        {errors.endDate && (
                          <span role="alert" className="error_text">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        <Button className="theme_btn text-end" type="submit">
                          <img src={filter} className="me-1" alt="" />
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
                <ListTable
                  headCells={districtManagementHead}
                  action={true}
                  ListData={districtManagementData}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default BankDeposit;
