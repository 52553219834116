import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import Select from "react-select";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
// const productHead = [
//   { id: "districtName", label: "District" },
//   { id: "schoolName", label: "School Name" },
//   { id: "studentNumber", label: "Student Number" },
//   { id: "studentName", label: "Student Name" },
//   { id: "houseHoldName", label: "Household Name" },
//   { id: "houseHoldEmail", label: "Household Eamil" },
//   { id: "studentMobile", label: "Student Mobile" },
//   { id: "studentEmail", label: "Student Email" },
//   { id: "studentAmount", label: "Amount" },
//   { id: "paymentReference", label: "Payment Reference" },
//   { id: "paymentDate", label: "Payment Date" },
//   { id: "paymentType", label: "Payment Type" },
// ];
const productHead = [
  { id: "status", label: "Status" },
  { id: "studentName", label: "Student Name" },
  { id: "studentNumber", label: "Students Id" },
  { id: "schoolName", label: "School Name" },
  { id: "districtName", label: "District Name" },
  { id: "schoolFees", label: "School Receives" },
  { id: "fees", label: "Fees" },
  { id: "totalAmount", label: "Total Amount" },
  { id: "paymentReference", label: "Payments Ref" },
  { id: "paymentDate", label: "Date" },
  { id: "dateUtc", label: "Date UTC" },
];
function Payments() {
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [reportDetails, setReportDetails] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [derror, setDerror] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    let seData = {
      schoolDistrictId: null,
      schoolId: null,
      studentNumber: "",
      studentName: "",
      houseHoldName: "",
      houseHoldEmail: "",
      studentMobile: "",
      studentEmail: "",
      amount: 0,
    };
    getPayment(seData);
    // eslint-disable-next-line
  }, []);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolDistrictId,
                label: sd?.districtName,
              };
            });
            setParentList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = (id) => {
    setSchoolList([]);
    setSchoolSelect([]);
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const dlist = res.data.payload?.map((sd) => {
                return {
                  value: sd?.schoolId,
                  label: sd?.schoolName,
                };
              });
              let uslist = [{ value: "all", label: "Select All" }, ...dlist];

              setSchoolList(uslist?.length > 1 ? uslist : []);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };

  const getPayment = (data) => {
    setDerror({ err: "" });

    setOpenCircle(true);
    let sendData = {
      ...data,
      schoolDistrictId: data?.schoolDistrictId ? data?.schoolDistrictId : null,
      schoolId:
        schoolSelect?.map((x) => x.value)?.length > 0
          ? schoolSelect?.map((x) => x.value)
          : [],
      amount: data?.amount ? parseFloat(data?.amount) : 0,
      userId: LogDetail?.userId,
      houseHoldName: "",
      houseHoldEmail: "",
      studentMobile: "",
      studentEmail: "",
    };
    let distName = parentList?.filter(
      (x) => x.value === sendData?.schoolDistrictId
    )[0]?.label;
    let schName =
      schoolSelect?.length === schoolList?.length - 1
        ? "All"
        : schoolSelect?.map((x) => x.label).toString();

    let reData = {
      distName: distName,
      schName: schName,
    };
    setReportDetails(reData);
    setPaymentList([]);
    Axios.post(`/api/Report/PaymentsReport`, sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dList = res.data.payload?.map((x) => {
              return {
                ...x,
                // fees: (
                //   Math.round((x?.stripeFees + x?.swanFees) * 100) / 100
                // ).toFixed(2),
                // totalAmount: (
                //   Math.round(
                //     (x?.stripeFees + x?.swanFees + x?.schoolFees) * 100
                //   ) / 100
                // ).toFixed(2),
                // schoolFees: (Math.round(x?.schoolFees * 100) / 100).toFixed(2),
                fees: (x?.stripeFees + x?.swanFees)?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
                totalAmount: (
                  x?.stripeFees +
                  x?.swanFees +
                  x?.schoolFees
                )?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
                schoolFees: x?.schoolFees?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
                paymentDate: x?.paymentDate
                  ? new Date(x?.paymentDate).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : "",
                dateUtc: x?.dateUtc
                  ? new Date(x?.dateUtc).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : "",
              };
            });
            setPaymentList(dList);
            setOpenCircle(false);
          } else {
            setPaymentList([]);
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };
  return (
    <>
      {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="payments-page">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <h4 className="all_title mb-4">Payments </h4>
                <form onSubmit={handleSubmit(getPayment)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            // required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {parentList &&
                            parentList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School</label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                            selected.length &&
                            selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                              ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                              : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Student Id</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Id"
                          {...register("studentNumber", {
                            // required: "This field is required",
                          })}
                        />
                        {errors.studentNumber && (
                          <span role="alert" className="error_text">
                            {errors.studentNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Student Name </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Name"
                          {...register("studentName", {
                            // required: "This field is required",
                          })}
                        />
                        {errors.studentName && (
                          <span role="alert" className="error_text">
                            {errors.studentName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                    <div className="form-group">
                      <label>House hold Name </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="House hold Name"
                        {...register("houseHoldName", {
                          // required: "This field is required",
                        })}
                      />
                      {errors.houseHoldName && (
                        <span role="alert" className="error_text">
                          {errors.houseHoldName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>House hold Email </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="House hold Email"
                        {...register("houseHoldEmail", {
                          // required: "This field is required",
                        })}
                      />
                      {errors.houseHoldEmail && (
                        <span role="alert" className="error_text">
                          {errors.houseHoldEmail.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Student Mobile </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Mobile "
                        {...register("studentMobile", {
                          // required: "This field is required",
                        })}
                      />
                      {errors.studentMobile && (
                        <span role="alert" className="error_text">
                          {errors.studentMobile.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Student Email </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Email "
                        {...register("studentEmail", {
                          // required: "This field is required",
                        })}
                      />
                      {errors.studentEmail && (
                        <span role="alert" className="error_text">
                          {errors.studentEmail.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Amount </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Amount"
                          {...register("amount", {
                            // required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid Number the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 75",
                            },
                            // valueAsNumber: true,
                          })}
                        />
                        {errors.amount && (
                          <span role="alert" className="error_text">
                            {errors.amount.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img src={filter} className="me-1" alt="" />
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end mb-3 filter-img">
                  <Button className="theme_btn text-end">
                    <img src={filter} className="me-1" />
                    Apply
                  </Button>
                </div> */}
                </form>
                <ListTable
                  headCells={productHead}
                  action={false}
                  ListData={paymentList}
                  reportDetails={reportDetails}
                  downloadFile={true}
                  dateRangeHide={true}
                  fileName="Payments Report"
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Payments;
