import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import ImgPopup from "../../../../../Components/Reusable/ImgPopup";
import defaultProfile from "../../../../../Assets/default_profile.jpg";
import { Config } from "../../../../../Utils/Config";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function DistrictManagement() {
  const [districtManagementData, setDistrictManagementData] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const districtManagementHead = [
    { id: "logo", label: "District Logo" },
    { id: "districtName", label: "District Name" },
    { id: "phoneNumber", label: "Phone Number" },
    { id: "email", label: "Email" },
    { id: "stateName", label: "State Name" },
    { id: "siteCode", label: "Site Code" },
    { id: "city", label: "City" },
    { id: "academicYear", label: "School Year" },
    { id: "rateType", label: "Rate Type" },
  ];
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    DistrictManagmentData();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const DistrictManagmentData = () => {
    setOpenCircle(true);
    Axios.get(
      // `/api/SchoolDistrict/GetAllSchoolDistrictList`,
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                ...sd,
                districtName: sd?.districtName,
                phoneNumber: sd?.phoneNumber,
                email: sd?.email,
                stateName: sd?.state,
                siteCode: sd?.siteCode,
                city: sd?.city,
                logo: (
                  <ImgPopup
                    img={
                      sd?.districtLogo
                        ? `${Config.API_HOST_URL_live}${sd?.districtLogo}`
                        : defaultProfile
                    }
                  />
                ),
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.schoolDistrictId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete District"}
                      text={sd?.districtName}
                      mtd={"post"}
                      url={`/api/SchoolDistrict/DeleteschoolDistrict?schoolDistrictId=${sd?.schoolDistrictId}`}
                    />
                  </>
                ),
              };
            });
            setDistrictManagementData(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="district_listpage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="district_hed">
                  <div className="all_title title">District Management</div>
                  {LogDetail?.roleId === 1 ? (
                    <div className="d-flex">
                      <Link to={"create"} className="theme_btn">
                        <BsPlusSquareDotted fontSize={24} className="me-3" />
                        Create
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <ListTable
                  headCells={districtManagementHead}
                  action={true}
                  ListData={districtManagementData}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default DistrictManagement;
