import React from "react";
import { useSelector } from "react-redux";
import AdminNavigation from "../Shared/AdminNavigation";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRouteAdmin() {
  const loggedIn = useSelector(
    (state) => state.login?.LoginDetails?.authenticate
  );
  return loggedIn ? (
    <>
      <AdminNavigation />
      <Outlet />
      {/* <Footer /> */}
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRouteAdmin;
