import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "roleName", label: "Role Name" },
  // { id: "schoolDistrictName", label: "District Name" },
  // { id: "parentRole", label: "Parent Role Name" },
  // { id: "description", label: "Description" },
  // { id: "createon", label: "Created On" },
  // { id: "createby", label: "Created By" },
  // { id: "lastupdateon", label: "LastUpdatedOn" },
  // { id: "lastupdateby", label: "LastUpdatedBy" },
];
function RoleManagement() {
  const [tbData, setTbData] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    RoleManagment();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const RoleManagment = () => {
    setOpenCircle(true);
    Axios.get(`/api/Roles/GetAllRoleData?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                roleId: sd?.roleId,
                roleName: sd?.roleName,
                // schoolDistrictName: sd?.schoolDistrictName,
                // description: sd?.description,
                createon: sd?.createdOn,
                createby: sd?.createdBy,
                lastupdateon: sd?.lastUpdatedOn,
                lastupdateby: sd?.lastUpdatedBy,
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.roleId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Role"}
                      text={sd?.roleName}
                      mtd={"post"}
                      url={`/api/Roles/DeleteRoleData?roleId=${sd?.roleId}`}
                    />
                  </>
                ),
              };
            });
            setTbData(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {/* <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
      </div> */}
        <section className="role_listpage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">Role List</div>
                  <div className="d-flex">
                    {/* <Link to={""} className="cancel_btn me-4">
                  Download
                </Link> */}
                    <Link to={"Create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={tbData}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default RoleManagement;
