import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import ListTable from "../../../../../Components/Reusable/ListTable";
import "./index.scss";
import { PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import DeletePopup from "../../../../../Components/Reusable/DeletePopup";
import { MdEdit } from "react-icons/md";
import { useForm } from 'react-hook-form';
import filter from "../../../../../Assets/PngImage/filter.png";
import Select from 'react-select';

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);
const incomeHead = [
    { id: "amount", label: "Amount" },
    { id: "incomeDate", label: "Income Date" },
    { id: "incomeCategoryName", label: "Income Category Name" },
    { id: "incomeSubCategoryName", label: "Income Sub Category Name" },
];

const FinanceIncomeList = () => {
    const [financeIncomeList, setFinanceIncomeList] = useState([]);
    const [singleSelect, setSingleSelect] = useState([]);
    const [schoolList, setSchoolList] = useState();
    const [distList, setDistList] = useState([]);
    const [open, setOpen] = useState(false);

    let deleteInfo = useSelector((state) => state.Common?.DeleteData);
    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const {
        handleSubmit,
        register,
        setValue,
        watch,
    } = useForm();

    useEffect(() => {
        const districtList = LogDetail?.districtClasses?.map((x) => {
            return {
                value: x?.schoolDistrictId,
                label: x?.schoolDistrictName,
            }
        })
        setDistList(districtList ?? []);
        const schoolList = LogDetail?.schoolClasses?.map((x) => {
            return {
                value: x?.schoolId,
                label: x?.schoolName,
                schId: x?.schoolDistrictId
            }
        })
        setSchoolList(schoolList ?? []);
        setTimeout(() => {
            setValue("schoolDistrictId", districtList[0]?.value);
            let filterSchool = schoolList?.filter((x) => x?.schId === districtList[0]?.value)
            setTimeout(() => {
                let schVAl
                if (filterSchool?.length > 1) {
                    filterSchool = [{ value: "all", label: "Select All" }, ...filterSchool];
                    setSingleSelect(filterSchool?.filter((x) => x?.value !== "all")?.map((c) => c?.value) ?? [])
                    schVAl = filterSchool?.filter((x) => x?.value !== "all")?.map((c) => c?.value) ?? [];
                } else {
                    setSingleSelect(filterSchool[0]?.value ?? []);
                    schVAl = filterSchool[0]?.value ? [filterSchool[0]?.value] : [];
                }
                getFinanceIncome({ schoolDistrictId: districtList[0]?.value, schList: schVAl });
                setValue("schList", schVAl);
            }, 500);
        }, 500);
        // eslint-disable-next-line
    }, [deleteInfo]);

    const handleDistrict = (sdId) => {
        setValue("schList", []);
        if (sdId) {
            setTimeout(() => {
                setValue("schoolDistrictId", sdId);
                let filterSchool = schoolList?.filter((x) => x?.schId === sdId)
                setTimeout(() => {
                    setSingleSelect(filterSchool?.value ?? [])
                }, 500);
            }, 500);
        } else {
            setSingleSelect([]);
            setValue("schList", []);
        }
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         getFinanceIncome({ dist: getValues('dist') });
    //     }, 1500)
    //     // eslint-disable-next-line 
    // }, [deleteInfo]);

    // formate date
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);

        return `${month}/${day}/${year}`;
    };

    // const getSchoolList = async (id) => {
    //     setSchoolList([]);
    //     if (id) {
    //         let seData = {
    //             url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
    //         };
    //         let res = await GetCallApi(seData);
    //         if (res?.status === 200) {
    //             if (res.data.success) {
    //                 const dlist = res.data.payload?.map((sd) => {
    //                     return {
    //                         value: sd?.schoolId,
    //                         label: sd?.schoolName,
    //                     };
    //                 });
    //                 setSchoolList(dlist?.length > 0 ? dlist : []);
    //             }
    //         }
    //     } else {
    //         setSchoolList([]);
    //     }
    // };

    // get finance income list
    const getFinanceIncome = async (data) => {
        setFinanceIncomeList([]);
        setOpen(true);
        let sendBody = {
            schoolDistrictId: data?.schoolDistrictId ? data?.schoolDistrictId : "00000000-0000-0000-0000-000000000000",
            schoolId: data?.schList ?? [],
        }
        let seData = {
            url: `/api/FinanceIncome/GetFinanceIncomesData`,
            body: sendBody
        };

        let resp = await PostCallApi(seData);
        setOpen(false);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                const sdData = resp.data.payload?.map((c) => {
                    return {
                        ...c,
                        amount: c?.amount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                        incomeDate: (
                            formatDate(c?.incomeDate)
                        ),
                        Action: (
                            <>
                                <Link
                                    type="button"
                                    className="btn_edit me-2"
                                    to={`edit/${c?.incomeId}`}
                                >
                                    <MdEdit fontSize={24} />
                                </Link>
                                <DeletePopup
                                    title={"Delete Finance Income"}
                                    mtd={"post"}
                                    url={`/api/FinanceIncome/DeleteFinanceIncome?incomeCategoryId=${c?.incomeId}`}
                                />
                            </>
                        ),
                    };
                });
                setFinanceIncomeList(sdData);
            } else {
                setOpen(false);
            }
        }
    };

    const handleIOCMulti = (e) => {
        setSingleSelect(Array.isArray(e) ? e.map((x) => x.value) : []);
        setValue("schList", Array.isArray(e) ? e.map((x) => x.value) : []);
    };

    let distSchool = schoolList?.filter((x) => x?.schId === watch(`schoolDistrictId`))
    let schOption
    if (distSchool?.length > 1) {
        schOption = [{ value: "all", label: "Select All" }, ...distSchool]
    } else {
        schOption = distSchool
    }

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="finance_income_listpage">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="product_box ">
                                    <h4 className="all_title mb-0">Finance Income List</h4>
                                    <div className="d-flex">
                                        <Link to={"create"} className="theme_btn">
                                            <BsPlusSquareDotted fontSize={24} className="me-3" />
                                            Create
                                        </Link>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(getFinanceIncome)}>
                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> District
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("schoolDistrictId", {
                                                        onChange: (e) => {
                                                            handleDistrict(e.target.value)
                                                            setSingleSelect([])
                                                        }
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {distList &&
                                                        distList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>School{" "}</label>
                                                <Select
                                                    isMulti
                                                    name="type"
                                                    // options={schoolList?.filter((x) => x?.schId === watch(`schoolDistrictId`))}
                                                    options={schOption}
                                                    className="form-neselect"
                                                    classNamePrefix="select"
                                                    placeholder="Select School"
                                                    // value={schoolList?.filter((x) => x?.schId === watch(`schoolDistrictId`))?.filter((obj) =>
                                                    //   singleSelect?.includes(obj.value)
                                                    // )}
                                                    value={schOption?.filter((obj) =>
                                                        singleSelect?.includes(obj.value)
                                                    )}
                                                    // onChange={handleIOCMulti}
                                                    onChange={(selected) => {
                                                        true &&
                                                            selected.length &&
                                                            selected.find(
                                                                (option) => option.value === "all"
                                                            )
                                                            ? handleIOCMulti(schOption.slice(1))
                                                            : !true
                                                                ? handleIOCMulti(
                                                                    (selected && selected.value) || null
                                                                )
                                                                : handleIOCMulti(selected);
                                                    }}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-auto mt-auto col-md-4">
                                            <div className="form-group text-end filter-img">
                                                {open ? (
                                                    <Button className="theme_btn text-end">
                                                        Applying Filter{" "}
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="theme_btn text-end" type="submit">
                                                        <img
                                                            src={filter}
                                                            className="me-1"
                                                            alt=""
                                                            height={23}
                                                        />
                                                        Apply
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <ListTable
                                    headCells={incomeHead}
                                    action={true}
                                    ListData={financeIncomeList}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    )
}

export default FinanceIncomeList