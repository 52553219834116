import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";

const ListTable = lazy(() =>
  import("../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ServingLine() {
  const [categoyList, setCategoyList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const productHead = [
    { id: "servingLineName", label: "Serving Line Name" },
    { id: "districtName", label: "District Name" },
    { id: "school", label: "School Name" },
  ];
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getOverListData();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const getOverListData = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/ServingLine/GetServingLineList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                servingLineName: sd?.servingLineName,
                districtName: sd?.schoolDistrictName,
                school: sd?.schoolName,
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.servingLineId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Serving Line"}
                      text={sd?.servingLineName}
                      mtd={"post"}
                      url={`/api/ServingLine/DeleteServingLine?servingLineId=${sd?.servingLineId}&loginUserId=${LogDetail?.userId}`}
                    />
                  </>
                ),
              };
            });
            setCategoyList(SdData);
          }
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="meals_list ">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">Serving Line</div>
                  <Link to={"create"} className="theme_btn">
                    <BsPlusSquareDotted fontSize={24} className="me-3" />
                    Create
                  </Link>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={categoyList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ServingLine;
