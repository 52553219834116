import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import Axios from "../../../../../Utils/AxiosConfig";
import pdfIcon from "../../../../../Assets/pdf_icon.svg";
import no_image from "../../../../../Assets/no_image.png";
import { useSelector } from "react-redux";
import { BsClockHistory } from "react-icons/bs";
import { Config } from "../../../../../Utils/Config";
import { PostCallApi } from "../../../../../Action/Action";
import Swal from "sweetalert2";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

// const productHead = [

//   { id: "address", label: "Address" },
//   { id: "apt", label: "APT" },
//   { id: "city", label: "City" },
//   { id: "state", label: "State" },
//   { id: "zip", label: "Zip" },
//   { id: "preferred", label: "Preferred Langue" },
//   { id: "pname", label: "Parents Name" },
//   { id: "email", label: "Email" },
// ];
const productHead = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "phone", label: "Phone" },
  { id: "email", label: "Email" },
  { id: "address", label: "Address" },
  { id: "familyCode", label: "familyCode" },
  // { id: "familyCodeImage", label: "Family Code Image/PDf" },
];
function PrimaryTab(props) {
  // const [overlayList, setOverlayList] = useState([]);
  const [overlaySelect, setOverlaySelect] = useState("All");
  const [openCircle, setOpenCircle] = useState(false);
  const { gotoCom, setParentId } = props;
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  // const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [prdList, setPrdList] = useState([]);
  const [prdAddAr, setPrdAddAr] = useState([]);

  const ComTab = (id) => {
    setParentId(id);
    gotoCom("3");
  };
  useEffect(() => {
    if (overlaySelect) {
      BalancePrimaryTabData(overlaySelect);
    }
    // eslint-disable-next-line
  }, [overlaySelect, deleteInfo]);

  const BalancePrimaryTabData = (id) => {
    setOpenCircle(true);
    Axios.get(`/api/Parents/GetAllParentsDataByFiltter?surveyStatus=${id}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                ...sd,
                phone: sd?.primaryPhoneNumber,
                Action: (
                  <>
                    <label className=" personal_detail_label">
                      <input
                        type="checkbox"
                        className="login_check"
                        name="Remember Me"
                        checked={false}
                        onChange={(e) =>
                          handleAddProduct(
                            e?.target?.checked,
                            sd?.parentsGuidId
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      onClick={() => ComTab(sd?.parentsGuidId)}
                    >
                      <BsClockHistory fontSize={24} />
                    </Link>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`/createprimaryTab/edit/${sd?.parentsGuidId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Parent"}
                      text={sd?.firstName + " " + sd?.lastName}
                      mtd={"post"}
                      url={`/api/Parents/DeleteParentData?parentGuidId=${sd?.parentsGuidId}`}
                    />
                    {sd?.familyCodeImage ? (
                      <Link
                        to={`${Config.API_HOST_URL_live}${sd?.familyCodeImage}`}
                        target="_blank"
                        // rel="noreferrer"
                        className="show_label_newpage ms-2"
                      >
                        <img src={pdfIcon} alt="" className="" />
                      </Link>
                    ) : (
                      <Link
                        to=""
                        className="show_label_newpage ms-2"
                        style={{ visibility: "hidden" }}
                      >
                        <img
                          src={no_image}
                          alt=""
                          className=""
                          width={28}
                          height={28}
                        />
                      </Link>
                    )}
                  </>
                ),
              };
            });
            setPrdList(SdData);
          } else {
            setOpenCircle(true);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const handleAddProduct = (val, parentsGuidId) => {
    if (val) {
      setPrdAddAr((oldArray) => [...oldArray, parentsGuidId]);
    } else {
      setPrdAddAr((oldArray) =>
        oldArray?.filter((data) => data !== parentsGuidId)
      );
    }
  };
  const handleSelectAll = (val, list) => {
    if (val) {
      setPrdAddAr(list?.map((x) => x.parentsGuidId));
    } else {
      setPrdAddAr([]);
    }
  };
  useEffect(() => {
    if (prdAddAr?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [prdAddAr]);
  const changetbData = (val) => {
    let newtb = prdList?.map((sd) => {
      return {
        ...sd,
        Action: (
          <>
            <label className=" personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  prdAddAr?.filter((x) => x === sd?.parentsGuidId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleAddProduct(e?.target?.checked, sd?.parentsGuidId)
                }
              />
              <span className="checkmark"></span>
            </label>
            <Link
              type="button"
              className="btn_edit me-2"
              onClick={() => ComTab(sd?.parentsGuidId)}
            >
              <BsClockHistory fontSize={24} />
            </Link>
            <Link
              type="button"
              className="btn_edit me-2"
              to={`/createprimaryTab/edit/${sd?.parentsGuidId}`}
            >
              <MdEdit fontSize={24} />
            </Link>
            <DeletePopup
              title={"Delete Parent"}
              text={sd?.firstName + " " + sd?.lastName}
              mtd={"post"}
              url={`/api/Parents/DeleteParentData?parentGuidId=${sd?.parentsGuidId}`}
            />
            {sd?.familyCodeImage ? (
              <Link
                to={`${Config.API_HOST_URL_live}${sd?.familyCodeImage}`}
                target="_blank"
                // rel="noreferrer"
                className="show_label_newpage ms-2"
              >
                <img src={pdfIcon} alt="" className="" />
              </Link>
            ) : (
              <Link
                to=""
                className="show_label_newpage ms-2"
                style={{ visibility: "hidden" }}
              >
                <img
                  src={no_image}
                  alt=""
                  className=""
                  width={28}
                  height={28}
                />
              </Link>
            )}
          </>
        ),
      };
    });
    setPrdList(newtb);
  };

  const AddStudents = async (data) => {
    let sendData = prdAddAr;

    setOpenCircle(true);
    let seData = {
      url: "/api/Parents/BulkEmailToParentFamilySurvay",
      body: sendData,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        setPrdAddAr([]);

        BalancePrimaryTabData();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setOpenCircle(false);
      }
    }
  };
  const drdpList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Submitted",
      label: "Submitted",
    },
    {
      value: "NotSubmitted",
      label: "Not Submitted",
    },
  ];
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <ListTable
          headCells={productHead}
          action={true}
          ListData={prdList}
          actionFirst={true}
          btnTxt={"Send Mail"}
          selectAllBtn={true}
          prdAddAr={prdAddAr}
          AddStudents={AddStudents}
          selectingAll={handleSelectAll}
          dropdown1={true}
          dropdownLabel={"Select Submitted"}
          dropList={drdpList}
          dropValue1={overlaySelect}
          setDropValue1={setOverlaySelect}
        />
      </Suspense>
    </>
  );
}

export default PrimaryTab;
