import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "manufacturerName", label: "Manufacturer Name" },
  // { id: "firstName", label: "First Name" },
  // { id: "lastName", label: "Last Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "glnnumber", label: "GLN Number" },
];
function Manufacturer() {
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [openCircle, setOpenCircle] = useState(false);

  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    GetManufacturerList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const GetManufacturerList = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/Manufacturer/GetAllManufacturerData?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);

            const SdData = res.data.payload?.map((sd, i) => {
              return {
                manufacturerName: sd?.manufacturer1,
                // firstName: sd?.firstName,
                // lastName: sd?.lastName,
                email: sd?.email,
                phone: sd?.phoneNumber,
                glnnumber: sd?.glnnumber,
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.manufacturerId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Manufacturer"}
                      text={sd?.manufacturer1}
                      mtd={"post"}
                      url={`/api/Manufacturer/DeleteManufacturerData?ManufacturerId=${sd?.manufacturerId}`}
                    />
                  </>
                ),
              };
            });
            setPrdList(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="manufacturer_listpage">
          <div className="main_wrapper">
            <div className="product_box ">
              <div className="all_title ProductTitle">Manufacturer</div>
              <div className="d-flex">
                {/* <Link to={""} className="cancel_btn me-4">
                  Download
                </Link> */}
                <Link to={"Create"} className="theme_btn">
                  <BsPlusSquareDotted fontSize={24} className="me-3" />
                  Create
                </Link>
              </div>
            </div>
            <ListTable
              headCells={productHead}
              action={true}
              ListData={prdList}
            />
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Manufacturer;
