import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import Select from "react-select";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import uploadimg from "../../../../../Assets/SvgImage/file-upload.svg";
import Swal from "sweetalert2";
// import "react-quill/dist/quill.snow.css";
// import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  // MediaEmbed,
  Paragraph,
  // Table,
  Undo,
  SourceEditing,
  GeneralHtmlSupport,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import CropNewModel from "../../Meals/CreateMeals/CropNewModel";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function SurveyFormViewCreate() {
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [derror, setDrr] = useState();
  const [distList, setDistList] = useState([]);
  const [selectDistrict, setSelectDistrict] = useState([]);
  const [editorData, setEditorData] = useState("");

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const editorRef = useRef(null);

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      getEditFormView(id);
    } else {
      getDistrict();
    }
    // eslint-disable-next-line
  }, [id]);

  const getEditFormView = async (id) => {
    setOpen(true);
    if (id) {
      let apiUrl = {
        url: `/api/SurveyTemplate/GetSurveyTemplatesDetailsById?surveyTemplateId=${id}`,
      };
      const res = await GetCallApi(apiUrl);
      setOpen(false);
      if (res?.status === 200 && res?.data?.success) {
        let allData = res?.data?.payload;
        setValue("LogoURLPath", allData?.logo);
        setValue("SurveyTemplateId", allData?.surveyTemplateId);
        setValue("Language", allData?.language);
        setValue("Link", allData?.link);
        setValue("SurveryTemplateType", allData?.surveryTemplateType);
        getDistrict(allData?.schoolDistrictId);
        setEditorData(allData?.description);
        // if (editorRef.current && allData?.description) {
        //   editorRef.current.setContent(allData.description);
        // }
      }
    }
  };

  const getDistrict = async (disId) => {
    let sendData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
    };
    const resp = await GetCallApi(sendData);
    if (resp?.status === 200) {
      if (resp.data.success) {
        let list = resp.data.payload?.map((c) => {
          return {
            value: c?.schoolDistrictId,
            label: c?.districtName,
          };
        });
        let allList = [{ value: "all", label: "Select All" }, ...list];
        setDistList(allList ?? []);
        if (disId && list) {
          setSelectDistrict(list?.filter((x) => x?.value === disId));
        }
      }
    }
  };

  const onSubmit = async (data) => {
    if (selectDistrict?.length > 0) {
      setDrr({ req: "" });
      // if (editorRef.current && editorRef.current.getContent() !== "") {
      //   let tempBody = editorRef.current.getContent();
      if (editorData) {
        let tempBody = editorData;
        setOpen(true);
        setDrr({ err: "" });
        let gData;
        gData = {
          ...data,
          CreatedBy: LogDetail?.userId,
          Description: tempBody,
          SchoolDistrictId: selectDistrict?.map((x) => x.value),
        };
        let seData = {
          url: `/api/SurveyTemplate/CreateSurveyTemplates`,
          body: gData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        };

        let respo = await PostCallApi(seData);
        setOpen(false);
        if (respo?.status === 200) {
          if (respo.data.success) {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: respo.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpen(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: respo?.data?.message
                ? respo?.data?.message
                : "Something went wrong please try again.",
              showConfirmButton: false,
              timer: 2000,
              color: "#ffffff",
              background: "red",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          }
        } else {
          setOpen(false);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: respo?.data?.message
              ? respo?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        setDrr({ err: "This field is required" });
      }
    } else {
      setDrr({ req: "This field is required" });
    }
  };

  const handleMulti = (e) => {
    setSelectDistrict(e);
  };

  // const handleFile = (e) => {
  //     if (e?.target?.files) {
  //         setValue("LogoImage", e?.target?.files[0]?.name);
  //         setFiles(e?.target?.files[0]);
  //     }
  // };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("LogoURL", url);
        setValue("LogoURLPath", url.name);
        setCroppedImageUrl(url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="survey_form_view">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">
                {id ? "Edit" : "Create"} Survey Guideline
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <p>
                    Each district is allowed only one record per survey type.
                    For example, for the Survey Guide Line Type, each district
                    can have only one record.
                  </p>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          District
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={distList}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select District"
                          value={selectDistrict ? selectDistrict : null}
                          onChange={(selected) => {
                            true &&
                            selected.length &&
                            selected.find((option) => option.value === "all")
                              ? handleMulti(distList.slice(1))
                              : !true
                              ? handleMulti(
                                  (selected && selected.value) || null
                                )
                              : handleMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.req && (
                          <span role="alert" className="d-block error_text">
                            {derror?.req}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Survey Type
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("SurveryTemplateType", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Survey Type</option>
                          <option value="SurveyIncomeGuidLine">
                            Survey Income Guideline
                          </option>
                          <option value="Instruction">Instruction</option>
                        </select>
                        {errors.SurveryTemplateType && (
                          <span role="alert" className="error_text">
                            {errors.SurveryTemplateType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Language
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("Language", {
                            required: "This field is required",
                          })}
                        >
                          <option value="English">English</option>
                          <option value="Hindi">Hindi</option>
                          <option value="Spanish">Spanish</option>
                          <option value="Chinese">Chinese</option>
                        </select>
                        {errors.Language && (
                          <span role="alert" className="error_text">
                            {errors.Language.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Survey Image</label>
                        <div className="upload_survey_logo">
                          <input
                            type="text"
                            className="form-control upload_box"
                            placeholder="No file Chosen"
                            readOnly
                            {...register("LogoURLPath", {})}
                          />
                          <input
                            type="file"
                            id="vendor_logo"
                            accept="image/*"
                            className="form-control"
                            style={{ display: "none" }}
                            {...register("LogoURL")}
                            onChange={handleFile}
                          />
                          <label
                            htmlFor="vendor_logo"
                            className="upload_img_label mb-0 "
                          >
                            <img src={uploadimg} className="img-fluid" alt="" />
                          </label>
                        </div>
                      </div>
                    </div>
                    {src && (
                      <CropNewModel
                        src={src}
                        filename={filename}
                        setCroppedImageUrl={setCroppedImageUrlCallback}
                        openCropModal={openCropModal}
                        setCropModal={setCropModal}
                        maxHeight={"400"}
                        maxWidth={"400"}
                      />
                    )}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Terms & Condition Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter terms & condition link"
                          {...register("Link", {
                            // required: "This field is required",
                          })}
                        />
                        {errors.Link && (
                          <span role="alert" className="error_text">
                            {errors.Link.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <style>
                        {`
                        .ck-powered-by { 
                        display: none !important;
                        }
                      `}
                      </style>
                      <div className="form-group">
                        <label>
                          Template Body<span className="red-color"> *</span>
                        </label>
                        {/* <Editor
                          apiKey="fqcxbtig1ej9urvytsii6mcw5dq31tym7f0fyt72h11lgpd8"
                          onInit={(_evt, editor) =>
                            (editorRef.current = editor)
                          }
                          initialValue=""
                          init={{
                            height: 500,
                            menubar: false,
                            branding: false, // Removes "Powered by TinyMCE" branding
                            statusbar: false, // Removes the status bar
                            plugins:
                              "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code wordcount Templates",
                            toolbar:
                              "undo Redo | blocks | " +
                              "Bold | Underline |Italic | Forecolor |Select all	 | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | source code | Switch to or from fullscreen mode |media | link image",

                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        /> */}

                        <CKEditor
                          editor={ClassicEditor}
                          data={editorData}
                          onReady={(editor) => {
                            editorRef.current = editor;
                          }}
                          onChange={(event, editor) =>
                            setEditorData(editor.getData())
                          }
                          config={{
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "source",
                              "sourceEditing",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              // "insertTable",
                              // "mediaEmbed",
                              // "image",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "indent",
                              "outdent",
                            ],
                            plugins: [
                              Bold,
                              Essentials,
                              Heading,
                              Indent,
                              IndentBlock,
                              Italic,
                              Link,
                              List,
                              // Image,
                              // MediaEmbed,
                              Paragraph,
                              // Table,
                              Undo,
                              SourceEditing,
                              GeneralHtmlSupport,
                            ],
                            sourceEditing: {
                              shouldNotRender: false, // Enable source editing
                            },
                            htmlSupport: {
                              allow: [
                                {
                                  name: /.*/,
                                  attributes: true,
                                  classes: true,
                                  styles: true,
                                },
                              ],
                            },
                            // initialData: "<h1>Hello from CKEditor 5!</h1>",
                          }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end mt-3">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SurveyFormViewCreate;
