import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
// import AdminDashboard from "./Modules/Admin/Pages/Dashboard";
import PrivateRouteAdmin from "./Routes/PrivateRouteAdmin";
import DistrictSetting from "./Modules/Admin/Pages/Settings/DistrictSetting";
import CreatedistrictSeeting from "./Modules/Admin/Pages/Settings/DistrictSetting/CreatedistrictSeeting/Create";
import Students from "./Modules/Admin/Pages/Students";
import StudentAllergenForm from "./Modules/Admin/Pages/StudentAllergenForm";
import Households from "./Modules/Admin/Pages/Households";
import Reports from "./Modules/Admin/Pages/Reports/Payments";
import CashierReport from "./Modules/Admin/Pages/Reports/CashierReport";
import CashierSessionReport from "./Modules/Admin/Pages/Reports/CashierSessionReport";
import CheckReport from "./Modules/Admin/Pages/Reports/CheckReport";
import Settings from "./Modules/Admin/Pages/Settings";
import ProductList from "./Modules/Admin/Pages/ProductMange/ProductList";
import ProductCategories from "./Modules/Admin/Pages/ProductMange/ProductCategories";
import ProductSubCategories from "./Modules/Admin/Pages/ProductMange/ProductSubCategories";
import CreateStudent from "./Modules/Admin/Pages/Students/StudentTab/CreateStudentTab";
import CreatePrimaryTab from "./Modules/Admin/Pages/Households/PrimaryTab/CreatePrimaryTab/Create";
import CreateSecondnaryTab from "./Modules/Admin/Pages/Households/SecondnaryTab/CreateSecondnaryTab/Create";
import SiteManagement from "./Modules/Admin/Pages/Settings/SiteManagement";
import RoleManagement from "./Modules/Admin/Pages/Settings/RoleManagement";
import UserManagement from "./Modules/Admin/Pages/Settings/UserManagement";
import CreateSiteManagment from "./Modules/Admin/Pages/Settings/SiteManagement/CreateSiteManagment/Create";
import CreateRoleManagment from "./Modules/Admin/Pages/Settings/RoleManagement/CreateRoleManagment/Create";
import StudentAttendance from "./Modules/Admin/Pages/Settings/StudentAttendance";
import CreateStudentAttendance from "./Modules/Admin/Pages/Settings/StudentAttendance/CreateStudentAttendance/Create";
import ReimbursementRate from "./Modules/Admin/Pages/Settings/ReimbursementRate";
import CreateReimbursementRate from "./Modules/Admin/Pages/Settings/ReimbursementRate/CreateReimbursementRate/Create";
// import PosAdminNavigation from './Modules/POS/Pages/Dashboard';
// import Student from './Modules/POS/Pages/Student';
// import POSReports from './Modules/POS/Pages/Reports';
// import POSPayment from './Modules/POS/Pages/POSPayment';
// import StudentDetails from './Modules/POS/Pages/Student/StudentDetails';
// import CashierSignin from './Modules/POS/Pages/CashierSignin';
import CreateCategories from "./Modules/Admin/Pages/ProductMange/ProductCategories/CreateCategory";
import CreateSubCategory from "./Modules/Admin/Pages/ProductMange/ProductSubCategories/CreateSubCategory";
import ProductUploadForm from "./Modules/Admin/Pages/ProductMange/ProductList/ProductUploadForm";
import MealsList from "./Modules/Admin/Pages/Meals";
import CreateMeals from "./Modules/Admin/Pages/Meals/CreateMeals";
import Manufacturer from "./Modules/Admin/Pages/Settings/Manufacturer";
import Distributor from "./Modules/Admin/Pages/Settings/Distributor";
import CreateDistributor from "./Modules/Admin/Pages/Settings/Distributor/CreateDistributor";
import CreateManufacturer from "./Modules/Admin/Pages/Settings/Manufacturer/CreateManufacturer";
import ResetPassword from "./Pages/ResetPassword";
import FamilyIncomeSurvey from "./Modules/Admin/PdfPage/FamilyIncomeSurvey";

import Profile from "./Modules/Web/Student/Profile";
import Dashboard from "./Modules/Web/Student/Dashboard";
import MealInformation from "./Modules/Web/Student/MealInformation";
import PaymentHistory from "./Modules/Web/Student/PaymentHistory";
import MakePayment from "./Modules/Web/Student/MakePayment";

import ParentDashboard from "./Modules/Web/Parent/Dashboard";
import ParentMakePayment from "./Modules/Web/Parent/MakePayment";
import ParentMealInformation from "./Modules/Web/Parent/MealInformation";
import ParentPaymentHistory from "./Modules/Web/Parent/PaymentHistory";
import ParentProfile from "./Modules/Web/Parent/Profile";

import HouseHoldMemberInformation from "./Modules/Admin/PdfPage/HouseHoldMemberInformation";
import HouseHoldMemberInformationShow from "./Modules/Admin/PdfPage/HouseHoldMemberInformationShow";
import OverlayMenu from "./Modules/Admin/Pages/OverlayMenu";
import CreateOverlay from "./Modules/Admin/Pages/OverlayMenu/CreateOverlayMenu";
import PrivateRouteStudent from "./Routes/PrivateRouteStudent";
import PrivateRouteParent from "./Routes/PrivateRouteParent";
import PaymentSuccess from "./Modules/Admin/Pages/PaymentSuccess";
import Menu from "./Modules/Admin/Pages/Settings/Menu";
import CreateMenu from "./Modules/Admin/Pages/Settings/Menu/CreateMenu/Create";
import RoleMenuAllocation from "./Modules/Admin/Pages/Settings/RoleMenuAllocation";

import ServingLine from "./Modules/Admin/Pages/ServingLine";
import CreateServingLine from "./Modules/Admin/Pages/ServingLine/CreateServingLine";
// import GenrateBarcode from './Modules/Admin/Pages/Students/GenrateBarcode';
import ManualOrderEntry from "./Modules/Admin/Pages/ManualOrderEntry";
import CreateManualOrderEntry from "./Modules/Admin/Pages/ManualOrderEntry/Create";
import TransactionLog from "./Modules/Admin/Pages/Reports/TransactionLog";
import CashierSideReport from "./Modules/Admin/Pages/CashierSideReport";
import UserProfile from "./Modules/Admin/Pages/UserProfile";
import SurveyReport from "./Modules/Admin/Pages/Reports/SurveyReport";

// import DepositSlipReport from './Modules/Admin/Pages/Reports/ManagerReport/DepositSlipReport';
// import AdjustmentsReport from './Modules/Admin/Pages/Reports/ManagerReport/AdjustmentsReport';
// import BankingReport from './Modules/Admin/Pages/Reports/ManagerReport/BankingReport';
// import MealCountDay from './Modules/Admin/Pages/Reports/ManagerReport/MealCountDay';
import DepositReport from "./Modules/Admin/Pages/Reports/DailyDepositReport";
import MealCountReport from "./Modules/Admin/Pages/Reports/MealCountReport";
import LogReport from "./Modules/Admin/Pages/Reports/LogReport";
// import FinancialReportRegisters from './Modules/Admin/Pages/Reports/ManagerReport/FinancialReportRegisters';
// import FinancialReportRevenueByEligibility from './Modules/Admin/Pages/Reports/ManagerReport/FinancialReportRevenueByEligibility';
// import ManagerCheckReport from './Modules/Admin/Pages/Reports/ManagerReport/ManagerCheckReport';
// import SchoolSpecialEvent from './Modules/Admin/Pages/Reports/ManagerReport/SchoolSpecialEvent';
import StudentRosterReport from "./Modules/Admin/Pages/Reports/StudentRosterReport";
import ItemizationReport from "./Modules/Admin/Pages/Reports/ItemizationReport";
import BankDeposit from "./Modules/Admin/Pages/Settings/BankDeposit";
import CreateBankDeposit from "./Modules/Admin/Pages/Settings/BankDeposit/CreateBankDeposit";
import CashierSessionApproval from "./Modules/Admin/Pages/Settings/CashierSessionApproval";
import SubmittedSurveys from "./Modules/Admin/Pages/Settings/SubmittedSurveys";
import SupportTicket from "./Components/SupportTicket";
import ParentSendText from "./Modules/Admin/Pages/Settings/ParentSendText";
import EligibilityReport from "./Modules/Admin/Pages/Reports/EligibilityReport";
import DCRTReport from "./Modules/Admin/Pages/Reports/DCRTReport";
import EditCashierSessionReport from "./Modules/Admin/Pages/Reports/CashierSessionReport/EditCashierSession";
import MonthEndPaperWork from "./Modules/Admin/Pages/Reports/MonthEndPaperWork/index";

import SmtpConfigList from "./Modules/Admin/Pages/BusinessRulesBuilder/SmtpConfig/index";
import CreateSmtpConfig from "./Modules/Admin/Pages/BusinessRulesBuilder/SmtpConfig/CreateSmtpConfig/index";
import TemplatesList from "./Modules/Admin/Pages/BusinessRulesBuilder/Template/index";
import CreateTemplates from "./Modules/Admin/Pages/BusinessRulesBuilder/Template/CreateTemplate/Create";
import CampaignList from "./Modules/Admin/Pages/BusinessRulesBuilder/CampaignList/index";
import CreateCampaignList from "./Modules/Admin/Pages/BusinessRulesBuilder/CampaignList/CreateCampaignList/Create";
import CreateCustomers from "./Modules/Admin/Pages/CustomersManagement/CustomersList/CreateCustomers";
import CustomersList from "./Modules/Admin/Pages/CustomersManagement/CustomersList/index";
import EditCreateCustomers from "./Modules/Admin/Pages/CustomersManagement/CustomersList/EditCreateCustomers/index";
import CreatePayment from "./Modules/Admin/Pages/CustomersManagement/PaymentList/CreatePayment";
import PaymentList from "./Modules/Admin/Pages/CustomersManagement/PaymentList/index";

import SessionGraph from "./Modules/Admin/Pages/SessionGraph";

import AdminNewDashboard from "./Modules/Admin/Pages/NewDashboard";
import ApproveEmployeeHoursReport from "./Modules/Admin/Pages/Reports/ApproveEmployeeHoursReport";
import FullCalendarComponent from "./Modules/Admin/Pages/Schedule";
import SendCommunicateEmails from "./Modules/Admin/Pages/Settings/SendCommunicateEmails";
import GlobalProductData from "./Modules/Admin/Pages/Settings/GlobalProductData";
import AllergenGroup from "./Modules/Admin/Pages/Settings/AllergenGroup";
import CreateAllergen from "./Modules/Admin/Pages/Settings/AllergenGroup/CreateAlleregn/create";
import UploadDocuments from "./Modules/Admin/Pages/StudentManagement/UploadDocuments";
import CommunicationHistory from "./Modules/Web/Parent/CommunicationHistory";
import StudentDocuments from "./Modules/Web/Parent/StudentDocument";
import StudentDocumentsUpload from "./Modules/Web/Parent/StudentDocumentUpload";
import AllergenGroupStudentReport from "./Modules/Admin/Pages/Reports/AllergenGroupStudentReport/index";
import StudentList from "./Modules/Web/Parent/StudentList";
import Transfer from "./Modules/Web/Parent/StudentList/Create/Transfer";
import CreateSurvey from "./Modules/Admin/Pages/Settings/SubmittedSurveys/Create";
import SubmittedSurvey from "./Modules/Web/Parent/SubmittedSurveyList";
import ViewSurvey from "./Modules/Web/Parent/SubmittedSurveyList/view";
import CreateSurveyParent from "./Modules/Web/Parent/SubmittedSurveyList/Create";
// import VendorList from "./Modules/Admin/Pages/FigFinance/Vendors";
// import CreateVendors from "./Modules/Admin/Pages/FigFinance/Vendors/Create";
import ExpenceCategoryList from "./Modules/Admin/Pages/FigFinance/ExpenceCategory";
import CreateExpenceCategory from "./Modules/Admin/Pages/FigFinance/ExpenceCategory/Create";
import ExpenceSubCategoryList from "./Modules/Admin/Pages/FigFinance/ExpenceSubCategory";
import CreateExpenceSubCategory from "./Modules/Admin/Pages/FigFinance/ExpenceSubCategory/Create";
import FinanceExpenceList from "./Modules/Admin/Pages/FigFinance/FinanceExpence";
import CreateFinanceExpence from "./Modules/Admin/Pages/FigFinance/FinanceExpence/Create";
import FinanceIncomeList from "./Modules/Admin/Pages/FigFinance/FinanceIncome";
import FinanceIncomeCategoryList from "./Modules/Admin/Pages/FigFinance/FinanceIncomeCategory";
import CreateFinanceIncomeCat from "./Modules/Admin/Pages/FigFinance/FinanceIncomeCategory/Create";
import FinanceIncomeSubCategoryList from "./Modules/Admin/Pages/FigFinance/FinanceIncomeSubCategory";
import CreateFinanceIncomeSubCat from "./Modules/Admin/Pages/FigFinance/FinanceIncomeSubCategory/Create";
import CreateFinanceIncome from "./Modules/Admin/Pages/FigFinance/FinanceIncome/Create";
import FinanceUnitMeasureList from "./Modules/Admin/Pages/FigFinance/FinanceUnitOfMeasure";
import CreateFinanceUnitMeasure from "./Modules/Admin/Pages/FigFinance/FinanceUnitOfMeasure/Create";
import FinanceVendorCatergoryList from "./Modules/Admin/Pages/FigFinance/FinanceVendorCategory";
import CreateFinanceVendorCat from "./Modules/Admin/Pages/FigFinance/FinanceVendorCategory/Create";
import FinanceVendorList from "./Modules/Admin/Pages/FigFinance/FinanceVendor";
import CreateFinanceVendors from "./Modules/Admin/Pages/FigFinance/FinanceVendor/Create";
import SchoolAcademicYearList from "./Modules/Admin/Pages/Students/SchoolAcademicYear";
import CreateAcademicYear from "./Modules/Admin/Pages/Students/SchoolAcademicYear/Create";
import FinancialDashboardReport from "./Modules/Admin/Pages/Reports/FinancialDashboardReport";
import InvoiceProcessingForm from "./Modules/Admin/Pages/Settings/InvoiceProcessingForm";
import MealPointList from "./Modules/Admin/Pages/Settings/MealPoints";
import CreateMealPoint from "./Modules/Admin/Pages/Settings/MealPoints/Create";
import MealPointsHistoryReport from "./Modules/Admin/Pages/Reports/MealPointsHistoryReport";
import DirectExpenseSummaryReport from "./Modules/Admin/Pages/Reports/DirectExpenseSummaryReport";
import CreateUserManagment from './Modules/Admin/Pages/Settings/UserManagement/CreateUserManagment/CreateUser';
import { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import SurveyFormViewCreate from "./Modules/Admin/Pages/SurveyFormView/Create";
import SurveyFormList from "./Modules/Admin/Pages/SurveyFormView";
import ApproveEmployeeHours from "./Modules/Admin/Pages/Settings/ApproveEmployeeHours";
import ShowPreviousSurveyData from "./Modules/Admin/PdfPage/ShowSubmitSurveyData";
import OperatingStatementReport from "./Modules/Admin/Pages/Reports/OperatingStatementReport";
import InvoiceList from "./Modules/Admin/Pages/Settings/InvoiceList";
import InvoiceCreate from "./Modules/Admin/Pages/Settings/InvoiceList/Create";
import InvoiceView from "./Modules/Admin/Pages/Settings/InvoiceList/View/Ivoiceview";
import OperatingReport from "./Modules/Admin/Pages/Reports/OperatingReport";
import CreateStudentForm from "./Modules/Admin/Pages/Students/StudentTab/CreateStudentTab/CreateNew";

function App() {

  const [mes, setMes] = useState()
  const [open, setOpen] = useState(true)
  useEffect(() => {
    getSite();
  }, [])
  const getSite = async () => {
    try {
      let res = await axios.get(`http://posapi.vidhaninfotech.com/api/Common/GetSiteOnOffStatus`)
      setOpen(false)
      if (res?.status === 200) {
        if (!res?.data?.success) {
          setMes(res?.data?.message)
        }
      }
    } catch (error) {
      console.log(error)
      setOpen(false)
    }
  }

  return (
    <>
      {open || mes ?
        open ?
          <div className="loader test">
            <CircularProgress sx={{ color: "#86c65a" }} />
          </div> :
          <div
            style={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <h1>
              {mes}
            </h1>
          </div>
        :
        <Router>
          <Routes>
            {/* start login and forgot passowrd */}
            <Route path="/" element={<Login />} />
            <Route path="/parent-login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password-parent" element={<ForgotPassword />} />
            <Route path="/reset-password/:email" element={<ResetPassword />} />
            <Route path="/reset-password-parent/:email" element={<ResetPassword />} />
            {/* end login and forgot passowrd */}

            {/* Admin Route Link */}
            <Route element={<PrivateRouteAdmin />}>

              {/* <Route path='/admin-newdashboard' element={<><AdminNewDashboard /></>} /> */}
              <Route path="/admin-dashboard" element={<><SessionGraph /></>} />
              <Route path="/reports/my-dashboard" element={<><AdminNewDashboard /></>} />
              <Route path="/support-ticket" element={<><SupportTicket /></>} />
              {/* <Route path='/my-dashboard' element={<><AdminDashboard /></>} /> */}
              {/* <Route path='/admin-dashboard' element={<><AdminDashboard /></>} /> */}
              {/* <Route path='/district-setting' element={<><DistrictSetting /></>} />
          <Route path='/district-setting/Create' element={<><CreatedistrictSeeting /></>} />
          <Route path='/district-setting/edit/:id' element={<><CreatedistrictSeeting /></>} /> */}

              {/* start report */}

              <Route path="/reports"  >
                <Route index element={<><Reports /></>} />
                <Route path="Payments" element={<><Reports /></>} />
                <Route path="cashierReport" element={<><CashierReport /></>} />
                <Route path="check-report" element={<><CheckReport /></>} />
                <Route path="transaction-log" element={<><TransactionLog /></>} />
                <Route path="cashier-side-report" element={<><CashierSideReport /></>} />
                <Route path="survey-report" element={<><SurveyReport /> </>} />
                <Route path="daily-deposit-report" element={<><DepositReport /> </>} />
                <Route path="meal-count-report" element={<> <MealCountReport /></>} />
                <Route path="log-report" element={<> <LogReport /></>} />
                <Route path="student-roster-report" element={<><StudentRosterReport /> </>} />
                <Route path="itemization-report" element={<><ItemizationReport /></>} />
                <Route path="eligibility-report" element={<> <EligibilityReport />  </>} />
                <Route path="dcrt-report" element={<> <DCRTReport />  </>} />
                <Route path="cashier-session-report" element={<> <CashierSessionReport />  </>} />
                <Route path="cashier-session-report/:id" element={<> <EditCashierSessionReport />  </>} />
                <Route path="approve-cashier-session-report" element={<> <ApproveEmployeeHoursReport /> </>} />
                <Route path="allergen-group-students-report" element={<> <AllergenGroupStudentReport />  </>} />
                <Route path="hhmi/create" element={<> <CreateSurvey />  </>} />
                <Route path="hhmi/edit/:id/:dId" element={<> <CreateSurvey />  </>} />
                <Route path="hhmi/view/:id/:dId" element={<> <HouseHoldMemberInformationShow />  </>} />
                <Route path="submitted-surveys" element={<> <SubmittedSurveys />  </>} />
                <Route path="month-end-paperwork" element={<> <MonthEndPaperWork />  </>} />
                <Route path="financial-dashboard" element={<> <FinancialDashboardReport />  </>} />
                <Route path="meal-points-history" element={<> <MealPointsHistoryReport />  </>} />
                <Route path="direct-expense-summary" element={<> <DirectExpenseSummaryReport />  </>} />
                <Route path="operating-statement" element={<> <OperatingStatementReport />  </>} />
                <Route path="staff-purchases-report" element={<> <CheckReport />  </>} />
                <Route path="operating-report" element={<> <OperatingReport />  </>} />
              </Route>
              {/* end report */}

              {/* Manager Report Start*/}
              {/* not using that's why hide it by Yash on 20/02/2024 */}
              {/* <Route path='/manager-report/deposit-slip-report' element={<><DepositSlipReport /></>} />
          <Route path='/manager-report/adjustments-report' element={<><AdjustmentsReport /></>} />
          <Route path='/manager-report/banking-report' element={<><BankingReport /></>} />
          <Route path='/manager-report/meal-count-day' element={<><MealCountDay /></>} />
          <Route path='/manager-report/financial-report-registers' element={<><FinancialReportRegisters /></>} />
          <Route path='/manager-report/financial-report-revenue-by-eligibility' element={<><FinancialReportRevenueByEligibility /></>} />
          <Route path='/manager-report/manager-check-report' element={<><ManagerCheckReport /></>} />
          <Route path='/manager-report/school-special-event' element={<><SchoolSpecialEvent /></>} /> */}
              {/* Manager Report End */}

              <Route path="/settings" element={<> <Settings />  </>} />
              <Route path="/user-profile" element={<> <UserProfile />  </>} />

              {/* start meal */}
              <Route path="/manual-meal-entry" element={<> <ManualOrderEntry />  </>} />
              <Route path="/bulk-entry" element={<> <CreateManualOrderEntry />  </>} />
              <Route path="/pos-items" element={<> <MealsList />  </>} />
              <Route path="/pos-items/create" element={<> <CreateMeals />  </>} />
              <Route path="/pos-items/edit/:id" element={<> <CreateMeals />  </>} />
              {/* start overlay */}
              <Route path="/overlay"  >
                <Route index element={<> <OverlayMenu />  </>} />
                <Route path="create" element={<> <CreateOverlay />  </>} />
                <Route path="edit/:id" element={<> <CreateOverlay />  </>} />
              </Route>
              {/* end overlay */}

              {/* start serving line */}
              <Route path="/serving-line" element={<> <ServingLine /> </>} />
              <Route path="/serving-line/create" element={<> <CreateServingLine />  </>} />
              <Route path="/serving-line/edit/:id" element={<> <CreateServingLine />  </>} />
              {/* end serving line */}

              {/* end meal */}

              {/* start product manage */}
              <Route path="/product-manage/list" element={<> <ProductList />  </>} />
              <Route path="/product-manage/list/create" element={<> <ProductUploadForm />  </>} />
              <Route path="/product-manage/list/edit/:id" element={<> <ProductUploadForm />  </>} />
              <Route path="/product-categories" element={<> <ProductCategories />  </>} />
              <Route path="/product-categories/create" element={<> <CreateCategories />  </>} />
              <Route path="/product-categories/edit/:id" element={<> <CreateCategories />  </>} />
              <Route path="/product-sub-categories" element={<> <ProductSubCategories />  </>} />
              <Route path="/product-sub-categories/create" element={<> <CreateSubCategory />  </>} />
              <Route path="/product-sub-categories/edit/:id" element={<> <CreateSubCategory />  </>} />
              {/* end product manage */}

              {/* start school management */}
              <Route path="/students" element={<> <Students />  </>} />
              <Route path="/households" element={<> <Households />  </>} />
              <Route path="/createstudent/create" element={<> <CreateStudent />  </>} />
              <Route path="/create-student" element={<> <CreateStudentForm />  </>} />
              <Route path="/createstudent/edit/:id" element={<> <CreateStudent />  </>} />
              <Route path="/createstudent/AllergenTab" element={<> <CreateStudent />  </>} />
              <Route path="/createprimaryTab/create" element={<> <CreatePrimaryTab />  </>} />
              <Route path="/createprimaryTab/edit/:id" element={<> <CreatePrimaryTab />  </>} />
              <Route path="/createsecondnaryTab/create" element={<><CreateSecondnaryTab />  </>} />
              <Route path="/createsecondnaryTab/edit/:id" element={<> <CreateSecondnaryTab />  </>} />
              <Route path="/household/sendtext" element={<> <ParentSendText />  </>} />
              <Route path="/school-academic-year/create" element={<> <CreateAcademicYear />  </>} />
              <Route path="/school-academic-year/edit/:id" element={<> <CreateAcademicYear />  </>} />
              <Route path="/school-academic-year" element={<> <SchoolAcademicYearList />  </>} />
              <Route path="/survey-guideline/create" element={<> <SurveyFormViewCreate />  </>} />
              <Route path="/survey-guideline/edit/:id" element={<> <SurveyFormViewCreate />  </>} />
              <Route path="/survey-guideline" element={<> <SurveyFormList />  </>} />
              {/* end school management */}

              {/* start setting */}
              <Route path="/settings/cashier-session-approval" element={<> <CashierSessionApproval /> </>} />
              {/* <Route path='/GenrateBarcode' element={<><GenrateBarcode /></>} /> */}
              <Route path="/settings/DistrictManagement" element={<> <DistrictSetting />  </>} />
              <Route path="/settings/DistrictManagement/create" element={<> <CreatedistrictSeeting />  </>} />
              <Route path="/settings/DistrictManagement/edit/:id" element={<> <CreatedistrictSeeting />  </>} />
              <Route path="/settings/RoleManagement" element={<> <RoleManagement />  </>} />
              <Route path="/settings/RoleManagement/Create" element={<> <CreateRoleManagment />  </>} />
              <Route path="/settings/RoleManagement/edit/:id" element={<> <CreateRoleManagment />  </>} />
              <Route path="/settings/UserManagement" element={<> <UserManagement />  </>} />
              <Route path="/settings/UserManagement/create" element={<> <CreateUserManagment />  </>} />
              <Route path="/settings/UserManagement/edit/:id" element={<> <CreateUserManagment />  </>} />
              <Route path="/settings/distributor" element={<> <Distributor /> </>} />
              <Route path="/settings/distributor/create" element={<> <CreateDistributor />  </>} />
              <Route path="/settings/distributor/edit/:id" element={<><CreateDistributor />  </>} />
              <Route path="/settings/manufacturer" element={<> <Manufacturer />  </>} />
              <Route path="/settings/manufacturer/create" element={<> <CreateManufacturer />  </>} />
              <Route path="/settings/manufacturer/edit/:id" element={<> <CreateManufacturer />  </>} />
              <Route path="/settings/SiteManagement" element={<> <SiteManagement />  </>} />
              <Route path="/settings/SiteManagement/Create" element={<> <CreateSiteManagment />  </>} />
              <Route path="/settings/SiteManagement/edit/:id" element={<> <CreateSiteManagment />  </>} />
              <Route path="/settings/menu" element={<><Menu />  </>} />
              <Route path="/settings/menu/Create" element={<> <CreateMenu />  </>} />
              <Route path="/settings/menu/edit/:id" element={<> <CreateMenu /> </>} />
              <Route path="/settings/roleMenu-allocation" element={<> <RoleMenuAllocation />  </>} />
              <Route path="/settings/StudentAttendance" element={<> <StudentAttendance />  </>} />
              <Route path="/settings/StudentAttendance/create" element={<> <CreateStudentAttendance />  </>} />
              <Route path="/settings/StudentAttendance/edit/:id" element={<> <CreateStudentAttendance />  </>} />
              <Route path="/settings/ReimbursementRate" element={<> <ReimbursementRate />  </>} />
              <Route path="/settings/ReimbursementRate/Create" element={<> <CreateReimbursementRate />  </>} />
              <Route path="/settings/ReimbursementRate/edit/:id" element={<> <CreateReimbursementRate />  </>} />
              <Route path="/settings/bank-deposit" element={<> <BankDeposit />  </>} />
              <Route path="/settings/bank-deposit/Create" element={<> <CreateBankDeposit />  </>} />
              <Route path="/settings/bank-deposit/edit/:id" element={<> <CreateBankDeposit />  </>} />
              <Route path="/settings/invoice-processing" element={<> <InvoiceProcessingForm />  </>} />
              <Route path="/settings/meal-point-list" element={<> <MealPointList />  </>} />
              <Route path="/settings/meal-point-list/:name/:id?" element={<> <CreateMealPoint />  </>} />
              <Route path="/settings/approve-employee-hours" element={<> <ApproveEmployeeHours />  </>} />
              <Route path="/settings/invoice-list" element={<> <InvoiceList />  </>} />
              <Route path="/settings/invoice-list/:name/:id?" element={<> <InvoiceCreate />  </>} />
              <Route path="/settings/invoice-list/view/:id" element={<> <InvoiceView />  </>} />
              {/* start allergen List Page */}
              <Route path="/settings/allergen-group" element={<> <AllergenGroup />  </>} />
              <Route path="/settings/allergen-group/Create" element={<> <CreateAllergen />  </>} />
              <Route path="/settings/allergen-group/edit/:id" element={<> <CreateAllergen />  </>} />
              {/* end allergen List Page */}
              {/* start global product data */}
              <Route path="/settings/global-product-data" element={<> <GlobalProductData />  </>} />
              {/* end global product data */}
              {/* end setting */}
              <Route path="/schedule" element={<> <FullCalendarComponent />  </>} />
              <Route path="/studentallergenform" element={<> <StudentAllergenForm /> </>} />
              <Route path="/paymentSuccess" element={<> <PaymentSuccess />  </>} />

              {/* start SmtpConfig */}
              <Route path="/fig-communication/smtp-config" element={<> <SmtpConfigList />  </>} />
              <Route path="/fig-communication/smtp-config/create" element={<> <CreateSmtpConfig />  </>} />
              <Route path="/fig-communication/smtp-config/edit/:id" element={<> <CreateSmtpConfig />  </>} />
              {/* end SmtpConfig */}

              {/* start Templates */}

              <Route path="/fig-communication/templates" element={<> <TemplatesList />  </>} />
              <Route path="/fig-communication/templates/create" element={<> <CreateTemplates />  </>} />
              <Route path="/fig-communication/templates/edit/:id" element={<> <CreateTemplates />  </>} />
              {/* end Templates */}

              {/* start Campaign */}
              <Route path="/fig-communication/campaigns" element={<> <CampaignList />  </>} />
              <Route path="/fig-communication/campaigns/create" element={<> <CreateCampaignList />  </>} />
              <Route path="/fig-communication/campaigns/edit/:id" element={<> <CreateCampaignList />  </>} />
              {/* end Campaign */}

              {/* start customers */}
              <Route path="/customers-Management/customers" element={<> <CustomersList />  </>} />
              <Route path="/customers-Management/customers/create" element={<> <CreateCustomers />  </>} />
              <Route path="/customers-Management/customers/edit/:id" element={<><EditCreateCustomers />  </>} />
              {/* <Route path='/customers-Management/customers/edit/:id' element={<><CreateCustomers /></> } /> */}
              {/* end customers */}

              {/* start Payment */}
              <Route path="/customers-Management/payments" element={<> <PaymentList />  </>} />
              <Route path="/customers-Management/payments/create" element={<> <CreatePayment />  </>} />
              {/* end Payment */}

              {/* start communication email */}
              <Route path="/fig-communication/send-communication-emails" element={<> <SendCommunicateEmails />  </>} />
              {/* end communication email */}

              {/* Admin Route Link */}

              {/* Start of Upload Student Documents Page */}
              <Route path="/school-management/document-gallery" element={<> <UploadDocuments />  </>} />
              {/* End of Upload Student Documents Page */}

              {/* Start Fig Finance */}

              {/* <Route path="/fig-finance/vendor-list" element={<> <VendorList />  </>} />
          <Route path="/fig-finance/vendor-list/create" element={<> <CreateVendors />  </>} />
          <Route path="/fig-finance/vendor-list/edit/:id" element={<><CreateVendors />  </>} /> */}

              {/* start expense category  */}
              <Route path="/fig-finance/expense-category-list" element={<> <ExpenceCategoryList /> </>} />
              <Route path="/fig-finance/expense-category-list/:name/:id?" element={<> <CreateExpenceCategory />  </>} />
              {/* end expense category  */}

              {/* start expense sub category  */}
              <Route path="/fig-finance/expense-sub-category-list" element={<> <ExpenceSubCategoryList />  </>} />
              <Route path="/fig-finance/expense-sub-category-list/:name/:id?" element={<> <CreateExpenceSubCategory /> </>} />
              {/* end expense sub category  */}

              {/* start expense  */}
              <Route path="/fig-finance/finance-expense" element={<> <FinanceExpenceList />  </>} />
              <Route path="/fig-finance/finance-expense/:name/:id?" element={<> <CreateFinanceExpence />  </>} />
              {/* end expense */}

              {/* start income category */}
              <Route path="/fig-finance/finance-income-category" element={<> <FinanceIncomeCategoryList />  </>} />
              <Route path="/fig-finance/finance-income-category/:name/:id?" element={<> <CreateFinanceIncomeCat />  </>} />
              {/* end income category */}

              {/* start income sub category */}
              <Route path="/fig-finance/finance-income-sub-category" element={<> <FinanceIncomeSubCategoryList />  </>} />
              <Route path="/fig-finance/finance-income-sub-category/:name/:id?" element={<><CreateFinanceIncomeSubCat />  </>} />
              {/* end income sub category */}

              {/* start finance income */}
              <Route path="/fig-finance/finance-income" element={<> <FinanceIncomeList />  </>} />
              <Route path="/fig-finance/finance-income/:name/:id?" element={<><CreateFinanceIncome />  </>} />
              {/* end finance income */}

              {/* start Finance Unit Of Measure */}
              <Route path="/fig-finance/finance-unit-of-measure" element={<> <FinanceUnitMeasureList />  </>} />
              <Route path="/fig-finance/finance-unit-of-measure/:name/:id?" element={<> <CreateFinanceUnitMeasure />  </>} />
              {/* end Finance Unit Of Measure */}

              {/* start Finance vendor category */}
              <Route path="/fig-finance/finance-vendor-category" element={<> <FinanceVendorCatergoryList />  </>} />
              <Route path="/fig-finance/finance-vendor-category/:name/:id?" element={<>    <CreateFinanceVendorCat />  </>} />
              {/* end Finance vendor category */}

              {/* start finance vendor */}
              <Route path="/fig-finance/finance-vendor" element={<>    <FinanceVendorList />  </>} />
              <Route path="/fig-finance/finance-vendor/:name/:id?/:pId?" element={<><CreateFinanceVendors /></>} />
              {/* start finance vendor */}

              {/* End Fig Finance */}
            </Route>

            {/* Admin End Route Links */}

            {/* <Route path='/pos-dashboard' element={<PosAdminNavigation />} />
          <Route path='/student' element={<Student />} />
          <Route path='/POSReports' element={<POSReports />} /> */}
            {/* <Route path='/POSPayment' element={<POSPayment />} />
          <Route path='/StudentDetails' element={<StudentDetails />} />
          <Route path='/CashierSignin' element={<CashierSignin />} /> */}
            <Route path="/familyIncomeSurvey" element={<FamilyIncomeSurvey />} />
            {/* <Route path='/houseHoldMemberInformation' element={<HouseHoldMemberInformation />} /> */}


            {/* start student  */}
            <Route path="/web-dashboard" element={<PrivateRouteStudent> <Dashboard />  </PrivateRouteStudent>} />
            <Route path="/profile" element={<PrivateRouteStudent><Profile />  </PrivateRouteStudent>} />
            <Route path="/meal-information" element={<PrivateRouteStudent> <MealInformation />  </PrivateRouteStudent>} />
            <Route path="/payment-history" element={<PrivateRouteStudent> <PaymentHistory />  </PrivateRouteStudent>} />
            <Route path="/make-payment" element={<PrivateRouteStudent> <MakePayment />  </PrivateRouteStudent>} />
            {/* end student  */}

            {/* start Parent  */}
            <Route path="/hhmi/:id/:dId" element={<> <HouseHoldMemberInformation />  </>} />
            <Route path="/hhmi/view/:id/:dId" element={<> <ShowPreviousSurveyData />  </>} />
            <Route path="/houseHoldMemberInformation" element={<PrivateRouteParent> <HouseHoldMemberInformation />  </PrivateRouteParent>} />
            <Route path="/parent-dashboard" element={<PrivateRouteParent> <ParentDashboard />  </PrivateRouteParent>} />
            <Route path="/parent-profile" element={<PrivateRouteParent> <ParentProfile />  </PrivateRouteParent>} />
            <Route path="/parent-meal-information" element={<PrivateRouteParent> <ParentMealInformation />  </PrivateRouteParent>} />
            <Route path="/parent-payment-history" element={<PrivateRouteParent> <ParentPaymentHistory />  </PrivateRouteParent>} />
            <Route path="/parent-make-payment" element={<PrivateRouteParent> <ParentMakePayment />  </PrivateRouteParent>} />
            <Route path="/communication-history" element={<PrivateRouteParent> <CommunicationHistory />  </PrivateRouteParent>} />
            <Route path="/student-list" element={<PrivateRouteParent> <StudentList /> </PrivateRouteParent>} />
            <Route path="/transfer-money/:id" element={<PrivateRouteParent> <Transfer />  </PrivateRouteParent>} />
            <Route path="/student-document" element={<PrivateRouteParent> <StudentDocuments />  </PrivateRouteParent>} />
            <Route path="/student-document-upload" element={<PrivateRouteParent> <StudentDocumentsUpload /> </PrivateRouteParent>} />
            <Route path="/submitted-surveys/create" element={<PrivateRouteParent> <CreateSurveyParent />  </PrivateRouteParent>} />
            <Route path="/submitted-surveys/edit/:id/:dId" element={<PrivateRouteParent> <CreateSurveyParent />  </PrivateRouteParent>} />
            <Route path="/submitted-surveys/view/:id/:dId" element={<PrivateRouteParent> <ViewSurvey />  </PrivateRouteParent>} />
            <Route path="/submitted-surveys-list" element={<PrivateRouteParent> <SubmittedSurvey />  </PrivateRouteParent>} />
            {/* end Parent  */}

          </Routes>
        </Router>
      }
    </>
  );
}

export default App;
