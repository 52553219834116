import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { useFieldArray, useForm } from "react-hook-form";
import {
  DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateCampaignList() {
  // const [startDate, setStartDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [tempList, setTempList] = useState([]);
  const [tempListwr, setTempListwr] = useState([]);
  const [selectedTime, setSelectedTime] = useState("00:00");

  const { id } = useParams();
  const navigate = useNavigate();

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const { fields, remove } = useFieldArray({
    control,
    name: "temp1",
  });

  useEffect(() => {
    if (id) {
      if (tempList?.length > 0) {
        getCampaignData(id);
      }
    } else {
      if (tempList?.length > 0) {
        const temp = [
          {
            templateId: "",
            balance: "",
          },
        ];
        setValue("temp1", temp);
      }
    }
    // eslint-disable-next-line
  }, [id, tempList]);

  useEffect(() => {
    if (id) {
      getEditedData(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getTempList();
    // eslint-disable-next-line
  }, []);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getEditedData = async (id) => {
    setOpen(true);

    let sendData = {
      url: `/api/Campaign/GetCampaignDataById?CampaignId=${id}`,
      headers: headers,
    };

    let res = await GetCallApi(sendData);
    setOpen(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let editsiteData = res?.data?.payload;
        setSelectedTime(editsiteData?.sendOnTime);
        setValue("startDate", editsiteData?.startDate?.split("T")[0]);
        setValue("campaignId", editsiteData?.campaignId);
        setValue("campaignName", editsiteData?.campaignName);
        setValue("type", editsiteData?.type);
        setValue("noOfTemplates", editsiteData?.noOfTemplates);
        setValue("minimumNoContactDays", editsiteData?.minimumNoContactDays);
        setValue("status", editsiteData?.status);
      }
    }
  };

  const getCampaignData = async (id) => {
    setOpen(true);

    let sendData = {
      url: `/api/Campaign/GetCampaignTemplatDataByCampaignId?CampaignsId=${id}`,
      headers: headers,
    };

    let res = await GetCallApi(sendData);
    setOpen(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let editsiteData = res?.data?.payload;
        // setCampTempId(editsiteData)
        if (editsiteData?.length > 0) {
          setValue("temp1", editsiteData);
        } else {
          const temp = [
            {
              templateId: "",
              balance: "",
            },
          ];
          setValue("temp1", temp);
        }
      } else {
        const temp = [
          {
            templateId: "",
            balance: "",
          },
        ];
        setValue("temp1", temp);
      }
    }
  };

  const onSubmit = async (data) => {
    setOpen(true);
    let sendData = {
      ...data,
      createdBy: LogDetail?.userId,
      schoolDistrictId: LogDetail?.schoolDistrictId,
      sendOnTime: selectedTime,
      noOfTemplates: data?.temp1?.length > 0 ? data?.temp1?.length : 1,
    };

    let seData = {
      url: `/api/Campaign/CreateCampaign`,
      body: sendData,
      headers: headers,
    };
    let respo = await PostCallApi(seData);
    setOpen(false);
    if (respo?.status === 200) {
      if (respo.data.success) {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        let camId = respo?.data?.payload?.campaignId;
        setValue("campaignId", camId);
        if (camId) {
          if (data?.temp1?.length > 0) {
            let campData = data?.temp1?.map((x, i) => {
              return {
                ...x,
                balance: x?.balance ? x?.balance : 0,
                sequenceNumber: x?.sequenceNumber ? x?.sequenceNumber : i + 1,
                campaignId: camId,
                createdBy: LogDetail?.userId,
              };
            });

            let seData1 = {
              url: `/api/Campaign/CreateCampaignTemplate?campaignId=${camId}`,
              body: campData,
              headers: headers,
            };

            let CampRespo = await PostCallApi(seData1);
            if (CampRespo?.status === 200) {
              if (CampRespo.data.success) {
                navigate(-1);
              }
            }
          } else {
            navigate(-1);
          }
        }
        // setOpen(false)
        // if (multipleAdd?.status === 200) {
        //   if (multipleAdd.data.success) {
        //     Swal.fire({
        //       position: "top-end",
        //       width: 400,
        //       // icon: "success",
        //       text: multipleAdd.data?.message,
        //       showConfirmButton: false,
        //       timer: 1500,
        //       color: "#ffffff",
        //       background: "#48953e",
        //       showClass: {
        //         popup: "animate__animated animate__fadeInDown",
        //       },
        //       hideClass: {
        //         popup: "animate__animated animate__fadeOutRight",
        //       },
        //     });
        //   }
        // }
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo?.data?.message
            ? respo?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setOpen(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: respo?.data?.message
          ? respo?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };

  const handleSetVal = (e) => {
    if (e) {
      if (id) {
        // let tmp = campData;
        if (tempList?.length > 0) {
          let newD = fields;
          let fl = fields?.length > parseInt(e);
          let te = newD?.map((x, i) => {
            if (i + 1 > parseInt(e)) {
              return null;
            }
            return x;
          });
          te = te?.filter((x) => x !== null);
          const temp = [
            ...Array(parseInt(!fl ? e - fields.length : e)).keys(),
          ]?.map((as) => {
            return {
              templateId: "",
              balance: "",
            };
          });
          setValue("temp1", fl ? te : [...newD, ...temp]);
        }
      } else {
        if (tempList?.length > 0) {
          let newD = fields;
          let fl = fields?.length > parseInt(e);
          let te = newD?.map((x, i) => {
            if (i + 1 > parseInt(e)) {
              return null;
            }
            return x;
          });
          te = te?.filter((x) => x !== null);
          const temp = [
            ...Array(parseInt(!fl ? e - fields.length : e)).keys(),
          ]?.map((as) => {
            return {
              templateId: "",
              balance: "",
            };
          });
          setValue("temp1", fl ? te : [...newD, ...temp]);
        }
      }
    } else {
      if (tempList?.length > 0) {
        setValue("temp1", [fields[0]]);
      }
    }
  };

  const getTempList = async () => {
    setOpen(true);
    let seData = {
      url: `/api/Template/GetTemplateList?schoolDistrictId=${LogDetail?.schoolDistrictId}`,
      headers: headers,
    };

    let resp = await GetCallApi(seData);
    setOpen(false);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        const sdData = resp.data.payload?.map((tem) => {
          return {
            ...tem,
          };
        });
        setTempList(sdData);
      }
    }
  };

  const handleCampDelete = async (cid, ind) => {
    setOpen(true);
    let caData = {
      url: `/api/Campaign/RemoveCampaignTemplate?CampaignTemplateId=${cid}`,
      body:{},
      headers: headers,
    };
    //let deres = await DeleteCallApi(caData);
    let deres = await PostCallApi(caData);
    setOpen(false);
    if (deres?.status === 200) {
      if (deres.data.success) {
        remove(ind);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: deres.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        // getEditedData(id)
        getCampaignData(id);
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: deres?.data?.message
            ? deres?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setOpen(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: deres?.data?.message
          ? deres?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  // Generate an array of time options
  const timeOptions = [];
  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      const timeValue = `${formattedHour}:${formattedMinute}`;
      timeOptions.push(timeValue);
    }
  }
  const handleDropManage = (e, index) => {
    const temp = [...Array(parseInt(fields?.length)).keys()]?.map((as, i) => {
      if (i !== index) {
        if (getValues(`temp1.${i}.templateId`) === e) {
          setValue(`temp1.${index}.templateId`, "");
          return Swal.fire({
            position: "top-center",
            width: 400,
            // icon: "success",
            text: "You can not add same template please choose other one",
            confirmButtonColor: "#808080",
            // color: "#ffffff",
            // background: "red",
            // showClass: {
            //   popup: "animate__animated animate__fadeInDown",
            // },
            // hideClass: {
            //   popup: "animate__animated animate__fadeOutRight",
            // },
          });
        }
      }
      return undefined;
    });
    if (temp?.length > 0) {
      setTempListwr(temp);
    } else {
      setTempListwr(tempListwr);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_campa">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">
                {id ? "Edit Campaign" : "Create Campaign"}
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Campaign Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Campaign Name "
                          {...register("campaignName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.campaignName && (
                          <span role="alert" className="error_text">
                            {errors.campaignName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Type
                          {/* <span className="red-color">*</span> */}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          id="selectmethod"
                          defaultValue=""
                          {...register("type", {
                            // required: "This field is required",
                          })}
                        >
                          {/* <option value="">Select Type</option> */}
                          <option value="Balancedbased">Balanced Based</option>
                          <option value="Timebased">Time Based</option>
                        </select>
                        {/* {errors.type && (
                        <span role="alert" className="error_text">
                          {errors.type.message}
                        </span>
                      )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Status
                          {/* <span className="red-color">*</span> */}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          id="selectmethod"
                          defaultValue=""
                          {...register("status", {
                            // required: "This field is required",
                          })}
                        >
                          {/* <option value="">Select status</option> */}
                          <option value="start">Start</option>
                          <option value="end">end</option>
                          <option value="re-start">Re-Start</option>
                        </select>
                        {/* {errors.status && (
                        <span role="alert" className="error_text">
                          {errors.status.message}
                        </span>
                      )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Start Date
                          {/* <span className="red-color">*</span> */}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("startDate", {
                            valueAsDate: true,
                          })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Notify On Time
                          {/* <span className="red-color">*</span>{" "} */}
                        </label>
                        <select
                          className="form-select"
                          id="time"
                          value={selectedTime}
                          onChange={handleTimeChange}
                        >
                          {timeOptions?.map((timeOption, index) => (
                            <option key={index} value={timeOption}>
                              {timeOption}
                            </option>
                          ))}
                        </select>
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Notify On Time "
                          {...register("sendOnTime", {
                            required: "This field is required",
                          })}
                        /> */}
                        {/* {errors.sendOnTime && (
                        <span role="alert" className="error_text">
                          {errors.sendOnTime.message}
                        </span>
                      )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          No Of Templates <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          id="selectmethod"
                          defaultValue=""
                          {...register("noOfTemplates", {
                            required: "This field is required",
                          })}
                          onChange={(e) => handleSetVal(e.target.value)}
                        >
                          <option value="">Select No of Templates</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        {errors.noOfTemplates && (
                          <span role="alert" className="error_text">
                            {errors.noOfTemplates.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Minimum No Contact Days{" "}
                          <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Minimum No Contact Days"
                          {...register("minimumNoContactDays", {
                            required: "This field is required",
                            valueAsNumber: true,
                          })}
                        />
                        {errors.minimumNoContactDays && (
                          <span role="alert" className="error_text">
                            {errors.minimumNoContactDays.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="form-group">
                      <label style={{ fontSize: "25px" }}>
                        Campaigns Templates
                      </label>
                      <hr />
                    </div>
                    {fields?.map((f, index) => (
                      <React.Fragment key={index}>
                        <div className="col-md-5" key={f.id}>
                          <div className="form-group">
                            <label>
                              Select Template
                              <span className="red-color">*</span>{" "}
                            </label>
                            <select
                              name=""
                              className="form-select"
                              id="selectmethod"
                              defaultValue=""
                              {...register(`temp1.${index}.templateId`, {
                                required: "This field is required",
                                onChange: (e) =>
                                  handleDropManage(e.target.value, index),
                              })}
                            >
                              <option value="">Select Templates</option>
                              {tempList &&
                                tempList?.map((t, i) => {
                                  return (
                                    <option key={i + 1} value={t?.templateId}>
                                      {t?.templateTitle}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.temp1?.[index]?.templateId && (
                              <span role="alert" className="error_text">
                                {errors.temp1?.[index]?.templateId.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>
                              Balance / Time
                              <span className="red-color">*</span>{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Balance"
                              {...register(`temp1.${index}.balance`, {
                                required: "This field is required",
                                valueAsNumber: true,
                              })}
                            />
                            {errors.temp1?.[index]?.balance && (
                              <span role="alert" className="error_text">
                                {errors.temp1?.[index]?.balance.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-2">
                          {index === 0 ? (
                            ""
                          ) : (
                            <>
                              {f?.campaignTemplateId ? (
                                <Button
                                  type="button"
                                  onClick={() =>
                                    handleCampDelete(
                                      f?.campaignTemplateId,
                                      index
                                    )
                                  }
                                  className="delete_btn"
                                >
                                  Delete
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="delete_btn"
                                >
                                  Delete
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>

                  {/* <div className="campaign-section">
              <div className="outer_div">
                <div className="all_title">Campaign Templates</div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-5">
                  <div className="form-group">
                    <label>
                      {" "}
                      Select Template <span className="red-color">*</span>
                    </label>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="form-control"
                        sx={{ width: "100%" }}
                        {...register("Template", {
                          required: "This field is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Template",
                          },
                        })}
                      >
                        <MenuItem value="">Select Template </MenuItem>
                        <MenuItem value={10}>1st-Notice-Balance</MenuItem>
                        <MenuItem value={20}>1st-Notice-Balance</MenuItem>
                        <MenuItem value={30}>1st-Notice-Balance</MenuItem>
                      </Select>
                      {errors.Template && (
                        <span role="alert" className="error_text">
                          {errors.Template.message}
                        </span>
                      )}
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label>
                      Enter Balance <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Balance"
                      {...register("Balance", {
                        required: "This field is required",
                        pattern: {
                          message: "Balance",
                        },
                      })}
                    />
                    {errors.Balance && (
                      <span role="alert" className="error_text">
                        {errors.Balance.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label>
                      {" "}
                      Select Template <span className="red-color">*</span>
                    </label>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="form-control"
                        sx={{ width: "100%" }}
                        {...register("Time", {
                          required: "This field is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Select Template",
                          },
                        })}
                      >
                        <MenuItem value="">Enter Balance </MenuItem>
                        <MenuItem value={10}>1st-Notice-Balance</MenuItem>
                        <MenuItem value={20}>1st-Notice-Balance</MenuItem>
                        <MenuItem value={30}>1st-Notice-Balance</MenuItem>
                      </Select>
                      {errors.Time && (
                        <span role="alert" className="error_text">
                          {errors.Time.message}
                        </span>
                      )}
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label>
                      Enter Balance <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Balance"
                      {...register("Balance", {
                        required: "This field is required",
                        pattern: {
                          message: "Balance",
                        },
                      })}
                    />
                    {errors.Balance && (
                      <span role="alert" className="error_text">
                        {errors.Balance.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label>
                      {" "}
                      Select Template <span className="red-color">*</span>
                    </label>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="form-control"
                        sx={{ width: "100%" }}
                        {...register("Time", {
                          required: "This field is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Select Template",
                          },
                        })}
                      >
                        <MenuItem value="">Enter Balance </MenuItem>
                        <MenuItem value={10}>1st-Notice-Balance</MenuItem>
                        <MenuItem value={20}>1st-Notice-Balance</MenuItem>
                        <MenuItem value={30}>1st-Notice-Balance</MenuItem>
                      </Select>
                      {errors.Time && (
                        <span role="alert" className="error_text">
                          {errors.Time.message}
                        </span>
                      )}
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <label>
                      Enter Balance <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Balance"
                      {...register("Balance", {
                        required: "This field is required",
                        pattern: {
                          message: "Balance",
                        },
                      })}
                    />
                    {errors.Balance && (
                      <span role="alert" className="error_text">
                        {errors.Balance.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateCampaignList;
