import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Box, Modal } from "@mui/material";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import Button from "@mui/material/Button";
import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import ListTable from "../../../../Components/Reusable/ListTable";
import DeletePopup from "../../../../Components/Reusable/DeletePopup";
import { MdEdit } from "react-icons/md";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import filter from "../../../../Assets/PngImage/filter.png";
import { BsPlusSquareDotted } from "react-icons/bs";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const bulkMealCountsHead = [
  { id: "schoolDistrictName", label: "District Name" },
  { id: "schoolName", label: "School Name" },
  { id: "cashierUserName", label: "Cashier Name" },
  { id: "mealService", label: "Meal Service" },
  { id: "price", label: "Price" },
  { id: "mealCount", label: "Meal Count" },
  { id: "paymentStatus", label: "Payment Status" },
  { id: "entryType", label: "Entry Type" },
  { id: "date", label: "Date" },
  // { id: "isReimbursableMeal", label: "Is ReimbursableMeal" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  p: 4,
};
function BulkList(props) {
  // const { EditBulkMealData, clCom, setOpenPop, openPop } = props;
  const { EditBulkMealData, clCom, setOpenPop } = props;
  const [bulkMealCountsList, setBulkMealCountsList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let SendData = {
      schoolDistrictId: getValues("schoolDistrictId"),
      schoolId: getValues("schoolId"),
      startDate: getValues("startDate"),
      endDate: getValues("endDate"),
      mealService: getValues("mealService"),
    };
    if (
      SendData?.startDate?.toString() !== "Invalid Date" &&
      SendData?.endDate?.toString() !== "Invalid Date"
    ) {
      mealListData(SendData);
    }
    // eslint-disable-next-line
  }, [clCom, deleteInfo]);
  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistrictList(dlist?.length > 0 ? dlist : []);
        if (dlist?.length === 1) {
          getSchoolList(dlist[0]?.value);
          setTimeout(() => {
            setValue("schoolDistrictId", dlist[0]?.value);
          }, 500);
        }
      }
    }
  };

  const getSchoolList = async (id) => {
    setSchoolList([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });

          setSchoolList(dlist?.length > 0 ? dlist : []);
        } else {
          setSchoolList([]);
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  const mealListData = async (data) => {
    setBulkMealCountsList([]);
    let sendData = {
      ...data,
      schoolDistrictId: data?.schoolDistrictId ? data?.schoolDistrictId : null,
      schoolId: data?.schoolId ? data?.schoolId : null,
      startDate: data?.startDate ? data?.startDate : null,
      endDate: data?.endDate ? data?.endDate : null,
      userId: LogDetail?.userId,
    };
    let seData = {
      url: `/api/BulkMeal/GetAllBulkMealCountsData`,
      body: sendData,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        const SdData = res.data.payload?.map((sd, i) => {
          return {
            // paymentStatus: sd?.bmc?.paymentStatus,
            // bulkMealCountId: sd?.bmc?.bulkMealCountId,
            // schoolDistrictId: sd?.bmc?.schoolDistrictId,
            // schoolDistrictName: sd?.schoolDistrictName,
            // schoolId: sd?.bmc?.schoolId,
            // schoolName: sd?.schoolName,
            // servingLineId: sd?.servingLineId,
            // cashierUserName: sd?.cashierUserName,
            // mealService: sd?.bmc?.mealService,
            // price: sd?.bmc?.price,
            // mealCount: sd?.bmc?.mealCount,
            ...sd,
            ...sd.bmc,
            date: sd?.bmc?.date
              ? new Date(sd?.bmc?.date)?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              : "",
            isReimbursableMeal:
              sd?.bmc?.isReimbursableMeal === true ? "Yes" : "No",
            price: sd?.bmc?.price?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            Action: (
              <>
                <Button
                  type="button"
                  className="btn_edit me-2"
                  // onClick={EditBulkMealData(sd?.bmc?.bulkMealCountId)}
                  onClick={() => EditBulkMealData(sd?.bmc?.bulkMealCountId)}
                >
                  <MdEdit fontSize={24} />
                </Button>
                <DeletePopup
                  title={"Delete Bulk Entry"}
                  text={sd?.cashierUserName+"'s Bulk Entry"}
                  mtd={"post"}
                  url={`/api/BulkMeal/DeleteBulkMealCountsData?bulkMealCountId=${sd?.bmc?.bulkMealCountId}`}
                />
              </>
            ),
          };
        });
        setBulkMealCountsList(SdData);
      } else {
        setOpenCircle(false);
      }
    }
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <div className="bulk_List_section">
        <div className="card mt-3">
          <div className="card-body">
            <div className="product_box ">
              <div className="all_title ProductTitle">Bulk Entry List</div>
              <div className="d-flex">
                <Button className="theme_btn" onClick={() => setOpenPop(true)}>
                  <BsPlusSquareDotted fontSize={24} className="me-3" />
                  Create
                </Button>
              </div>
            </div>
            <form onSubmit={handleSubmit(mealListData)}>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label> District</label>
                    <select
                      name=""
                      className="form-select"
                      {...register("schoolDistrictId", {
                        onChange: (e) => getSchoolList(e.target.value),
                      })}
                    >
                      <option value="">Select School District</option>
                      {districtList &&
                        districtList?.map((data, i) => (
                          <option key={i} value={data?.value}>
                            {data?.label}
                          </option>
                        ))}
                    </select>
                    {errors.schoolDistrictId && (
                      <span role="alert" className="error_text">
                        {errors.schoolDistrictId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label> School</label>

                    <select className="form-select" {...register("schoolId")}>
                      <option value="">Select School </option>
                      {schoolList &&
                        schoolList?.map((item, i) => (
                          <option key={i} value={item?.value}>
                            {item?.label}
                          </option>
                        ))}
                    </select>
                    {errors.schoolId && (
                      <span role="alert" className="error_text">
                        {errors.schoolId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Start Date
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("startDate", {
                        required: "This field is required",
                        valueAsDate: true,
                      })}
                    />
                    {errors.startDate && (
                      <span role="alert" className="error_text">
                        {errors.startDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>End Date
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("endDate", {
                        required: "This field is required",
                        valueAsDate: true,
                        validate: (val) => {
                          if (watch("startDate") > val) {
                            return "Your start date can not greater than from end date";
                          }
                        },
                      })}
                    />
                    {errors.endDate && (
                      <span role="alert" className="error_text">
                        {errors.endDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Meal Service</label>
                    <select
                      className="form-select"
                      {...register("mealService", {
                        required: "This field is required",
                      })}
                    >
                      {/* <option value="All">All</option> */}
                      <option value="Breakfast">Breakfast</option>
                      <option value="Lunch">Lunch</option>
                      <option value="Dinner">Dinner</option>
                      <option value="Snacks">Snacks</option>
                    </select>
                    {errors.mealService && (
                      <span role="alert" className="error_text">
                        {errors.mealService.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-9 text-end">
                  {openCircle ? (
                    <Button className="theme_btn text-end">
                      Applying Filter{" "}
                      <CircularProgress
                        sx={{
                          color: "#fff",
                          marginLeft: "0.5rem",
                          height: "23px !important",
                          width: "23px !important",
                        }}
                      />
                    </Button>
                  ) : (
                    <Button className="theme_btn text-end" type="submit">
                      <img src={filter} className="me-1" alt="" height={23} />
                      Apply
                    </Button>
                  )}
                </div>
              </div>
            </form>
            <ListTable
              headCells={bulkMealCountsHead}
              action={true}
              ListData={bulkMealCountsList}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function CreateManualOrderEntry() {
  const [userList, setUserList] = useState();
  const [servingList, setServingList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const [overDe, setOverDe] = useState();
  const [clCom, setClCom] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  // const [entryType, setEntryType] = useState("");
  const [entryTypeList, setEntryTypeList] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getEntryTypeList();
    // eslint-disable-next-line
  }, []);

  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolDistrictId,
                label: sd?.districtName,
              };
            });
            setDistrictList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getEntryTypeList = async () => {
    let seData = {
      url: `/api/Common/GetEntryTypes`,

      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload;
        setEntryTypeList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getSchoolList = (id) => {
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const dlist = res.data.payload?.map((sd) => {
                return {
                  value: sd?.schoolId,
                  label: sd?.schoolName,
                };
              });
              setSchoolList(dlist?.length > 0 ? dlist : []);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };
  const GetCashierList = (district, sch) => {
    setUserList([]);
    if (district && sch) {
      Axios.get(
        `/api/User/GetUserDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userType=Cashier`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload?.map((sd, i) => {
                return {
                  name: sd?.name,
                  userId: sd?.userId,
                };
              });
              setUserList(SdData?.length > 0 ? SdData : []);
            } else {
              setUserList([]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setUserList([]);
    }
  };

  const GetServingList = (district, sch) => {
    if (district && sch) {
      Axios.get(
        `/api/ServingLine/GetServingLineDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userId=${LogDetail?.userId}`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload;
              setServingList(SdData);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setServingList([]);
    }
  };
  const handleshowDrop = (e, ml) => {
    if (e) {
      GetServingList(getValues("schoolDistrictId"), e);
      GetCashierList(getValues("schoolDistrictId"), e);
    } else {
    }
  };

  const onSubmit = (data) => {
    let sendData = {
      ...data,
      price: 0,
      // otherEntryTypeDescription:
      //   entryType === "Other" ? data.otherEntryTypeDescription : "",
      createdBy: LogDetail?.userId,
    };
    setOpenCircle(true);
    Axios.post("/api/BulkMeal/CreateBulkMealCounts", sendData, {
      headers,
    })
      .then((res) => {
        setOpenCircle(false);
        if (res?.status === 200) {
          if (res.data.success) {
            reset();
            setOverDe();
            setClCom(!clCom);
            handleClosePop();
            // navigate(-1);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (schoolList) {
      if (overDe) {
        setValue("schoolId", overDe?.bmc?.schoolId);
      }
      // if(id){
      // }
    }
    // eslint-disable-next-line
  }, [schoolList, overDe]);
  useEffect(() => {
    if (servingList) {
      if (overDe) {
        setValue("servingLineId", overDe?.bmc?.servingLineId);
      }
      // if(id){
      // }
    }
    // eslint-disable-next-line
  }, [servingList, overDe]);
  useEffect(() => {
    if (userList) {
      if (overDe) {
        setValue("cashierUserID", overDe?.bmc?.cashierUserId);
      }
      // if(id){
      // }
    }
    // eslint-disable-next-line
  }, [userList, overDe]);
  const EditBulkMealData = (id) => {
    Axios.get(`/api/BulkMeal/GetBulkMealCountsDataByID?bulkMealCountId=${id}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let meData = res.data.payload;
            setOpenPop(true);
            setOverDe(meData);
            setValue("bulkMealCountId", meData?.bmc?.bulkMealCountId);
            setValue("schoolDistrictId", meData?.bmc?.schoolDistrictId);
            getSchoolList(meData?.bmc?.schoolDistrictId);
            GetServingList(
              meData?.bmc?.schoolDistrictId,
              meData?.bmc?.schoolId
            );
            GetCashierList(
              meData?.bmc?.schoolDistrictId,
              meData?.bmc?.schoolId
            );
            setValue("mealService", meData?.bmc?.mealService);
            setValue(
              "date",
              meData?.bmc?.date ? meData?.bmc?.date.split("T")[0] : ""
              // meData?.bmc?.date ? convert(meData?.bmc?.date?.split("T")[0]) : ""
            );
            setValue("mealCount", meData?.bmc?.mealCount);
            setValue("paymentStatus", meData?.bmc?.paymentStatus);
            setValue("entryType", meData?.bmc?.entryType);
            setValue(
              "otherEntryTypeDescription",
              meData?.bmc?.otherEntryTypeDescription
            );
            // setEntryType(meData?.bmc?.entryType);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  // function convert(str) {
  //   var date = new Date(str?.replace(/-/g, "/")),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  // }
  // useEffect(() => {
  //   // Get the static section element
  //   const staticSection = document.querySelector(".bulk_create_section");
  //   const sec = document.querySelector(".bulk_entry");

  //   // Calculate the offset position to become sticky
  //   const offset = sec.offsetTop;

  //   // Function to make the section sticky
  //   function makeSticky() {
  //     if (window.pageYOffset >= offset) {
  //       if (window.innerWidth > 426) {
  //         staticSection.classList.add("sticky");
  //       }
  //     } else {
  //       staticSection.classList.remove("sticky");
  //     }
  //   }

  //   // Add an event listener to call the makeSticky function when scrolling
  //   window.addEventListener("scroll", makeSticky);
  // }, []);
  const handleClosePop = () => {
    setOpenPop(false);
    reset();
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="bulk_entry">
          <div className="main_wrapper position-relative">
            {openPop && (
              <>
                <Modal
                  open={openPop}
                  onClose={handleClosePop}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="toggle_delete_data"
                >
                  <Box sx={style} className="delete_data_box">
                    <div className="bulk_create_section">
                      <div className="product_box ">
                        <div className="all_title ProductTitle">Bulk Entry</div>
                      </div>
                      {/* <div className="card mt-3">
                        <div className="card-body"> */}
                      <form className="my-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label> District</label>
                              <select
                                name=""
                                className="form-select"
                                {...register("schoolDistrictId", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    getSchoolList(e.target.value),
                                })}
                              >
                                <option value="">Select School District</option>
                                {districtList &&
                                  districtList?.map((data, i) => (
                                    <option key={i} value={data?.value}>
                                      {data?.label}
                                    </option>
                                  ))}
                              </select>
                              {errors.schoolDistrictId && (
                                <span role="alert" className="error_text">
                                  {errors.schoolDistrictId.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label> School</label>
                              <select
                                className="form-select"
                                {...register("schoolId", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleshowDrop(e.target.value),
                                })}
                              >
                                <option value="">Select School </option>
                                {schoolList.map((item, i) => (
                                  <option key={i} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                              {errors.schoolId && (
                                <span role="alert" className="error_text">
                                  {errors.schoolId.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label> Serving Line</label>
                              <select
                                name=""
                                className="form-select"
                                {...register("servingLineId", {
                                  required: "This field is required",
                                  valueAsNumber: true,
                                })}
                              >
                                <option value="">Select Serving Line</option>
                                {servingList &&
                                  servingList?.map((data, i) => (
                                    <option key={i} value={data?.servingLineId}>
                                      {data?.servingLineName}
                                    </option>
                                  ))}
                              </select>
                              {errors.servingLineId && (
                                <span role="alert" className="error_text">
                                  {errors.servingLineId.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label> Meal Service</label>
                              <select
                                className="form-select"
                                {...register("mealService", {
                                  required: "This field is required",
                                })}
                              >
                                <option value="">Select Meal Service </option>
                                <option value="Breakfast">Breakfast</option>
                                <option value="Dinner">Dinner</option>
                                <option value="Lunch">Lunch</option>
                                <option value="Snacks">Snacks</option>
                              </select>
                              {errors.mealService && (
                                <span role="alert" className="error_text">
                                  {errors.mealService.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>
                                Meal Count
                                <span className="red-color">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Meal Count"
                                min="1"
                                {...register("mealCount", {
                                  required: "This field is required",
                                  valueAsNumber: true,
                                })}
                              />
                              {errors.mealCount && (
                                <span className="formError errorMssg error_text">
                                  {errors.mealCount.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>
                                Meal Date
                                <span className="red-color">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Meal Date"
                                {...register("date", {
                                  required: "This field is required",
                                })}
                              />
                              {errors.date && (
                                <span className="formError errorMssg error_text">
                                  {errors.date.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label> Cashier</label>
                              <select
                                name=""
                                className="form-select"
                                {...register("cashierUserID", {
                                  required: "This field is required",
                                })}
                              >
                                <option value="">Select Cashier</option>
                                {userList &&
                                  userList?.map((data, i) => (
                                    <option key={i} value={data?.userId}>
                                      {data?.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.cashierUserID && (
                                <span role="alert" className="error_text">
                                  {errors.cashierUserID.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label> Payment Type</label>
                              <select
                                name=""
                                className="form-select"
                                {...register("paymentStatus", {
                                  required: "This field is required",
                                })}
                              >
                                <option value="">Select Payment Type</option>
                                <option value="F">FREE</option>
                                <option value="P">PAID</option>
                                {/* <option value="R">REDUCED</option>
                          <option value="FOSTER CHILD">FOSTER CHILD</option>
                          <option value="SNAP">SNAP</option>
                          <option value="TNAF">TNAF</option> */}
                              </select>
                              {errors.paymentStatus && (
                                <span role="alert" className="error_text">
                                  {errors.paymentStatus.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label> Entry Type</label>
                              <select
                                name=""
                                className="form-select"
                                {...register("entryType", {
                                  required: "This field is required",
                                  // onChange: (e) =>
                                  //   setEntryType(e.target.value),
                                })}
                              >
                                <option value="">Select Entry Type</option>
                                {entryTypeList &&
                                  entryTypeList?.map((ent, i) => (
                                    <option key={i} value={ent.stateName}>
                                      {ent?.stateName}
                                    </option>
                                  ))}
                                {/* <option value="Clicked Meals">
                                      Clicked Meals
                                    </option>
                                    <option value="Field Trip">
                                      Field Trip
                                    </option>
                                    <option value="Class Room">
                                      Class Room
                                    </option>
                                    <option value="Other">Other</option> */}
                              </select>
                              {errors.entryType && (
                                <span role="alert" className="error_text">
                                  {errors.entryType.message}
                                </span>
                              )}
                            </div>
                          </div>
                          {/* {entryType === "Other" ? (
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>Other Entry Type </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Other Entry Type"
                                      {...register(
                                        "otherEntryTypeDescription",
                                        {
                                          required: "This field is required",
                                        }
                                      )}
                                    ></input>
                                    {errors.otherEntryTypeDescription && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.otherEntryTypeDescription
                                            .message
                                        }
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )} */}
                          <div className="col-md-12 text-end mt-3">
                            <Button type="submit" className="theme_btn me-2">
                              Submit
                            </Button>
                            <Button
                              className="cancel_btn"
                              onClick={handleClosePop}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* </div>
                    </div> */}
                  </Box>
                </Modal>
              </>
            )}

            <BulkList
              EditBulkMealData={EditBulkMealData}
              clCom={clCom}
              openPop={openPop}
              setOpenPop={setOpenPop}
            />
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateManualOrderEntry;
