import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import { Box, Modal } from "@mui/material";
import { DeleteCallApi, PostCallApi } from "../../../Action/Action";
import { DeleteDataFor } from "../../../reducers/Common";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function ToggleButton(props) {
  const [open, setOpen] = useState(false);
  const [openCircle, setOpenCircle] = React.useState(false);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    url,
    checked,
    disabled,
    offstyle = "btn-danger",
    onstyle = "btn-success",
  } = props;
  const [checkvalue, setCheckValue] = useState(true);
  useEffect(() => {
    if (checked === "0") {
      setCheckValue(false);
    } else {
      setCheckValue(true);
    }
  }, [checked]);
  let displayStyle = checkvalue ? onstyle : offstyle;
  const dispatch = useDispatch();

  const handlechnage = (e) => {
    handleOpen();
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handledelete = async () => {
    let seData = { url: url, headers: headers };
    setOpenCircle(true);
    //let responce = await DeleteCallApi(seData);
    let responce
    if (props?.mtd === "post") {
      responce = await PostCallApi(
        { ...seData, body: {} }
      );
    }
    else {
      responce = await DeleteCallApi(seData);
    }
    setOpenCircle(false);
    if (responce.status === 200) {
      if (responce.data.success) {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: responce.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        dispatch(DeleteDataFor(deleteInfo ? false : true));
        handleClose();
        if (checkvalue === true) {
          setCheckValue(false);
        } else {
          setCheckValue(true);
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: responce?.data?.message
            ? responce?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: responce?.data?.message
          ? responce?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  return (
    <>
      <label className="toggle_button ms-2" key={props.key}>
        <span className={`switch-wrapper`}>
          <input
            type="checkbox"
            checked={checkvalue}
            disabled={disabled}
            onChange={(e) => handlechnage(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
      </label>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data1"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                {/* <h6>{props?.title}</h6>  */}
                <div className="popup_delete_detail">
                  <h5>Alert</h5>
                  <p className="delete_text">
                    Are you sure you want
                    {checkvalue ? " InActive" : " Active"}
                    <span className=""> {props?.text}</span>?
                  </p>
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handledelete}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(ToggleButton);
