import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
// import Select from "react-select";
import { useForm } from "react-hook-form";
import DeletePopup from "../../../../Components/Reusable/DeletePopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 800 : "90%",
  maxHeight: "80%",
  overflow: "auto",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
export default function EditSchedulePopup(props) {
  const { GetScheduleCall, open, setOpen, openDetail } = props;
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  // const [derror, setDerror] = useState();
  // const [schList, setSchList] = useState();
  // const [districtList, setDistrictList] = useState([]);
  const [breackList, setBreackList] = useState([]);
  const [durTime, setDurTime] = React.useState();

  const [openCircle, setOpenCircle] = React.useState(false);
  // const [userList, setUserList] = React.useState([]);
  // const [userSelect, setUserSelect] = React.useState([]);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDurTime();
    // setUserSelect([]);
    // setSchList([]);
    // setUserList([]);
    reset();
    // setDerror({ err: "" });
  };
  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (open) {
      GetEditData();
      // getDistrictList();
      generateBreakTimeSlots();
    }
    // eslint-disable-next-line
  }, [open]);
  const GetEditData = async () => {
    let seData = {
      url: `/api/EmployeeShedualHour/GetEmployeeShedualHourById?employeeShedualHoursId=${openDetail?.employeeShedualHoursId}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data?.success) {
        let Erh = res?.data?.payload;
        setValue("employeeShedualHoursId", Erh?.employeeShedualHoursId);
        setValue("schoolDistrictId", Erh?.schoolDistrictId);
        setValue("schoolId", Erh?.schoolId);
        setValue("userId", Erh?.userId);
        setValue("startDate", Erh?.startDate?.split("T")[0]);
        let st = Erh?.startDate?.split("T")[1]?.split(":");
        let et = Erh?.endDate?.split("T")[1]?.split(":");
        setValue("startTime", st[0] + ":" + st[1]);
        setValue("endTime", et[0] + ":" + et[1]);
        // setValue("endDate", Erh?.endDate);
        setValue("breakTime", Erh?.breakTime);
        setValue("remark", Erh?.remark);
        setValue("userName", Erh?.userName);
        setValue("schoolName", Erh?.schoolName);
        setValue("schoolDistrictName", Erh?.schoolDistrictName);
        // setValue("mealService", Erh?.mealService);
        setDurTime(Erh?.shiftDuration);
      }
    }
  };
  // const getDistrictList = async () => {
  //   let seData = {
  //     url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
  //     headers: headers,
  //   };
  //   let res = await GetCallApi(seData);
  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       const dlist = res.data.payload;
  //       setDistrictList(dlist?.length > 0 ? dlist : []);
  //     }
  //   }
  // };
  // const getSchoolList = async (id) => {
  //   setSchList([]);

  //   if (id) {
  //     let seData = {
  //       url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
  //       headers: headers,
  //     };
  //     let res = await GetCallApi(seData);

  //     if (res?.status === 200) {
  //       if (res.data.success) {
  //         const dlist = res.data.payload?.map((sd) => {
  //           return {
  //             value: sd?.schoolId,
  //             label: sd?.schoolName,
  //           };
  //         });
  //         let uslist = [...dlist];

  //         setSchList(uslist?.length > 0 ? uslist : []);
  //       }
  //     }
  //   } else {
  //     setSchList([]);
  //   }
  // };
  // const getUserList = async (id) => {
  //   setUserList([]);

  //   if (id) {
  //     let seData = {
  //       url: `/api/School/GetUserDataBySchoolId?schoolId=${id}&userId=${LogDetail?.userId}`,
  //       headers: headers,
  //     };
  //     setOpenCircle(true);
  //     let res = await GetCallApi(seData);
  //     setOpenCircle(false);
  //     if (res?.status === 200) {
  //       if (res.data.success) {
  //         const dlist = res.data.payload?.map((sd) => {
  //           return {
  //             ...sd,
  //             value: sd?.userId,
  //             label: sd?.userName,
  //           };
  //         });
  //         let uslist = [...dlist];

  //         setUserList(uslist?.length > 0 ? uslist : []);
  //       }
  //     }
  //   } else {
  //     setUserList([]);
  //   }
  // };
  // const handleIOCMulti = (e) => {
  //   setUserSelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };
  const AddSchduleUser = async (data) => {
    let is_success = true;
    // if (userSelect?.length === 0) {
    //   is_success = false;
    //   setDerror({ err: "Select minimum one user" });
    // }
    if (is_success) {
      let seData = {
        url: `/api/EmployeeShedualHour/CreateEmployeeShedualHour`,
        body: {
          ...data,
          listUserId: [data?.userId],
          createdBy: LogDetail?.userId,
          isDiscarded: false,
          isPublished: false,
          startDate: data?.startDate + "T" + data?.startTime,
          endDate: data?.startDate + "T" + data?.endTime,
          shiftDuration: durTime,
          publishClass: "#f5803e",
        },
        headers: headers,
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          GetScheduleCall();

          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          handleClose();
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res?.data?.message
              ? res?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };
  const generateBreakTimeSlots = () => {
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 0; // start time

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      times[i] = ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }

    setBreackList(times);
    // return breakSlots;
  };
  const EmplScheduleDelete = async () => {
    handleClose();
    GetScheduleCall();
  };
  function timeDistance(start, end) {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) hours = hours + 24;
    if (start?.length > 1 && end?.length > 1) {
      setDurTime(
        (hours <= 9 ? "0" : "") +
          hours +
          ":" +
          (minutes <= 9 ? "0" : "") +
          minutes
      );
    }
  }
  return (
    <>
      {/* <Button className="theme_btn me-2" onClick={handleOpen}>
        Add Schedule
      </Button> */}

      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => 9999 }}
                open={openCircle}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div
                className="popup_delete_detail_box"
                style={{
                  display: "inline-block",
                  overflow: "auto",
                }}
              >
                <h6>Edit Schedule</h6>
                <div className="p-4">
                  <form onSubmit={handleSubmit(AddSchduleUser)}>
                    <div className="row">
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">School District</label>
                          <input
                            name=""
                            className="form-select"
                            type="text"
                            disabled
                            {...register("schoolDistrictName", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.schoolDistrictName && (
                            <span role="alert" className="error_text">
                              {errors.schoolDistrictName.message}
                            </span>
                          )}
                                                  </div>
                      </div> */}

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label"> School</label>
                          <input
                            name=""
                            className="form-select"
                            type="text"
                            disabled
                            {...register("schoolName", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.schoolName && (
                            <span role="alert" className="error_text">
                              {errors.schoolName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label"> Meal Service</label>
                          <select
                            className="form-select"
                            {...register("mealService", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Meal Service </option>
                            <option value="Breakfast">Breakfast</option>
                            <option value="Dinner">Dinner</option>
                            <option value="Lunch">Lunch</option>
                            <option value="Snacks">Snacks</option>
                          </select>
                          {errors.mealService && (
                            <span role="alert" className="error_text">
                              {errors.mealService.message}
                            </span>
                          )}
                        </div>
                      </div> */}

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label"> User</label>
                          <input
                            name=""
                            className="form-select"
                            type="text"
                            disabled
                            {...register("userName", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.userName && (
                            <span role="alert" className="error_text">
                              {errors.userName.message}
                            </span>
                          )}
                          {/* <Select
                            isMulti
                            name="type"
                            options={userList}
                            // closeMenuOnSelect={false}
                            // hideSelectedOptions={false}
                            className="form-neselect"
                            classNamePrefix="select"
                            placeholder="Select User"
                            value={userList?.filter((obj) =>
                              userSelect?.includes(obj.value)
                            )}
                            onChange={handleIOCMulti}
                            style={{ width: "100%" }}
                          />

                          {derror?.err && (
                            <span role="alert" className="d-block error_text">
                              {derror.err}
                            </span>
                          )} */}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label"> Date</label>
                          <input
                            name=""
                            className="form-control"
                            type="date"
                            {...register("startDate", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.startDate && (
                            <span role="alert" className="error_text">
                              {errors.startDate.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="meeting-time" className="form-label">
                            {" "}
                            Start Time
                          </label>
                          <input
                            name="meeting-type"
                            className="form-control"
                            type="time"
                            id="meeting-stime"
                            {...register("startTime", {
                              required: "This field is required",
                              onChange: (e) =>
                                timeDistance(
                                  e.target.value,
                                  getValues("endTime")
                                ),
                            })}
                          ></input>
                          {errors.startTime && (
                            <span role="alert" className="error_text">
                              {errors.startTime.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="meeting" className="form-label">
                            {" "}
                            end Time
                          </label>
                          <input
                            name="meeting-123"
                            className="form-control"
                            type="time"
                            id="meeting"
                            {...register("endTime", {
                              required: "This field is required",
                              onChange: (e) =>
                                timeDistance(
                                  getValues("startTime"),
                                  e.target.value
                                ),
                              validate: (val) => {
                                if (watch("startTime") >= val) {
                                  return "Your end time can not smaller than from start time";
                                }
                              },
                            })}
                          ></input>
                          {errors.endTime && (
                            <span role="alert" className="error_text">
                              {errors.endTime.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label"> Break Time</label>
                          <select
                            className="form-select"
                            {...register("breakTime", {
                              required: "This field is required",
                              validate: (val) => {
                                if (durTime <= val) {
                                  return "Your break time can not greater than from total duration";
                                }
                              },
                            })}
                          >
                            {breackList &&
                              breackList?.map((x, i) => (
                                <option key={x} value={x}>
                                  {x}
                                </option>
                              ))}
                          </select>
                          {errors.breakTime && (
                            <span role="alert" className="error_text">
                              {errors.breakTime.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label"> Total Duration</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="00:00"
                            disabled
                            value={durTime}
                            defaultValue={""}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label"> Comment</label>
                          <textarea
                            name=""
                            rows={3}
                            placeholder="Comment"
                            className="form-control"
                            {...register("remark")}
                          ></textarea>
                          {errors.remark && (
                            <span role="alert" className="error_text">
                              {errors.remark.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <Button type="submit" className="theme_btn ">
                      Submit
                    </Button>
                    <Button
                      type="button"
                      className="cancel_btn mx-3"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <DeletePopup
                      title={"Delete Schedule"}
                      text={openDetail?.userName + "'s Schedule"}
                      mtd={"post"}
                      showText={true}
                      CallBack={true}
                      CallBackAPi={EmplScheduleDelete}
                      url={`/api/EmployeeShedualHour/DeleteEmployeeShedualHour?employeeShedualHoursId=${openDetail?.employeeShedualHoursId}`}
                    />
                  </form>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
