import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { MdEdit, MdRemoveRedEye } from "react-icons/md";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const DeletePopup = lazy(() =>
    import("../../../../../Components/Reusable/DeletePopup")
);
const ListTable = lazy(() =>
    import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);
const InvoiceHead = [
    { id: "invoiceNumber", label: "Invoice Number" },
    { id: "vendorName", label: "Vendor" },
    { id: "templateName", label: "Template Name" },
    { id: "totalAmount", label: "Total Amount" },
    { id: "emailCounter", label: "Total Email Sent" },
    { id: "invoiceDate", label: "Invoice Date" },
    { id: "createdOn", label: "created On" },
];

const InvoiceList = () => {
    const [invoiceList, setInvoiceList] = useState([]);
    const [open, setOpen] = useState(false);
    // const [openButton, setOpenButton] = useState(false);
    // const [invoiceId, setInvoiceId] = useState();

    const LogDetail = useSelector((state) => state.login.LoginDetails);

    useEffect(() => {
        getInvoiceList();
        // eslint-disable-next-line
    }, [])

    function convert(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    // useEffect(() => {
    //     if (openButton) {
    //         let sendLoadData = invoiceList?.map((c) => {
    //             if (c?.invoiceId === invoiceId) {
    //                 return {
    //                     ...c,
    //                     invoiceDate: convert(c?.invoiceDate?.split("T")[0]),
    //                     createdOn: convert(c?.createdOn?.split("T")[0]),
    //                     totalAmount: c?.totalAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    //                     Action: (
    //                         <>
    //                             <Link
    //                                 type="button"
    //                                 className="btn_edit me-2"
    //                                 target="_blank"
    //                                 to={`view/${c?.invoiceId}`}
    //                             >
    //                                 <MdRemoveRedEye fontSize={24} />
    //                             </Link>
    //                             <Link
    //                                 type="button"
    //                                 className="btn_edit me-2"
    //                                 to={`edit/${c?.invoiceId}`}
    //                             >
    //                                 <MdEdit fontSize={24} />
    //                             </Link>
    //                             <DeletePopup
    //                                 title={"Delete Invoice"}
    //                                 text={c?.vendorName}
    //                                 url={`/api/Invoices/DeleteInvoice?invoiceId=${c?.invoiceId}`}
    //                             />
    //                             <Link className="loader_button">
    //                                 Sending{" "}
    //                                 <CircularProgress
    //                                     sx={{
    //                                         color: "#fff",
    //                                         marginLeft: "0.5rem",
    //                                         height: "18px !important",
    //                                         width: "18px !important",
    //                                     }}
    //                                 />
    //                             </Link>
    //                         </>
    //                     ),
    //                 }
    //             } else {
    //                 return {
    //                     ...c,
    //                     invoiceDate: convert(c?.invoiceDate?.split("T")[0]),
    //                     createdOn: convert(c?.createdOn?.split("T")[0]),
    //                     totalAmount: c?.totalAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    //                     Action: (
    //                         <>
    //                             <Link
    //                                 type="button"
    //                                 className="btn_edit me-2"
    //                                 target="_blank"
    //                                 to={`view/${c?.invoiceId}`}
    //                             >
    //                                 <MdRemoveRedEye fontSize={24} />
    //                             </Link>
    //                             <Link
    //                                 type="button"
    //                                 className="btn_edit me-2"
    //                                 to={`edit/${c?.invoiceId}`}
    //                             >
    //                                 <MdEdit fontSize={24} />
    //                             </Link>
    //                             <DeletePopup
    //                                 title={"Delete Invoice"}
    //                                 text={c?.vendorName}
    //                                 url={`/api/Invoices/DeleteInvoice?invoiceId=${c?.invoiceId}`}
    //                             />
    //                             <Link
    //                                 className="add-log-btn ms-2"
    //                                 onClick={() => handleSendEmail(c)}
    //                             >
    //                                 Send Email
    //                             </Link>
    //                         </>
    //                     ),
    //                 }
    //             }
    //         })
    //         setInvoiceList(sendLoadData);
    //     } else {
    //         let sendLoadData1 = invoiceList?.map((c) => {
    //             return {
    //                 ...c,
    //                 invoiceDate: convert(c?.invoiceDate?.split("T")[0]),
    //                 createdOn: convert(c?.createdOn?.split("T")[0]),
    //                 totalAmount: c?.totalAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
    //                 Action: (
    //                     <>
    //                         <Link
    //                             type="button"
    //                             className="btn_edit me-2"
    //                             target="_blank"
    //                             to={`view/${c?.invoiceId}`}
    //                         >
    //                             <MdRemoveRedEye fontSize={24} />
    //                         </Link>
    //                         <Link
    //                             type="button"
    //                             className="btn_edit me-2"
    //                             to={`edit/${c?.invoiceId}`}
    //                         >
    //                             <MdEdit fontSize={24} />
    //                         </Link>
    //                         <DeletePopup
    //                             title={"Delete Invoice"}
    //                             text={c?.vendorName}
    //                             url={`/api/Invoices/DeleteInvoice?invoiceId=${c?.invoiceId}`}
    //                         />
    //                         <Link
    //                             className="add-log-btn ms-2"
    //                             onClick={() => handleSendEmail(c)}
    //                         >
    //                             Send Email
    //                         </Link>
    //                     </>
    //                 ),
    //             }
    //         })
    //         setInvoiceList(sendLoadData1);
    //     }
    //     // eslint-disable-next-line
    // }, [openButton])

    const handleSendEmail = async (eData) => {
        // setInvoiceId(eData?.invoiceId);
        // setOpenButton(true);
        setOpen(true);
        let seEmail = {
            url: `/api/Invoices/sendInvoiceMailForVendor?invoiceId=${eData?.invoiceId}&createdBy=${LogDetail?.userId}`,
        }

        const resp = await PostCallApi(seEmail);
        // setOpenButton(false);
        setOpen(false);
        if (resp?.status === 200 && resp?.data?.success) {
            getInvoiceList();
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: resp?.data.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "#48953e",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        } else {
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: resp?.data?.message
                    ? resp?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    }

    // get expence List
    const getInvoiceList = async () => {
        setInvoiceList([]);
        setOpen(true);

        let seData = {
            url: `/api/Invoices/GetInvoiceData`,
        };

        let resp = await GetCallApi(seData);
        setOpen(false);
        if (resp?.status === 200 && resp?.data?.success) {
            const sdData = resp.data.payload?.map((c) => {
                return {
                    ...c,
                    invoiceDate: convert(c?.invoiceDate?.split("T")[0]),
                    createdOn: convert(c?.createdOn?.split("T")[0]),
                    totalAmount: c?.totalAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                    Action: (
                        <>
                            <Link
                                type="button"
                                className="btn_edit me-2"
                                target="_blank"
                                to={`view/${c?.invoiceId}`}
                            >
                                <MdRemoveRedEye fontSize={24} />
                            </Link>
                            <Link
                                type="button"
                                className="btn_edit me-2"
                                to={`edit/${c?.invoiceId}`}
                            >
                                <MdEdit fontSize={24} />
                            </Link>
                            <DeletePopup
                                title={"Delete Invoice"}
                                text={c?.vendorName}
                                mtd={"post"}
                                url={`/api/Invoices/DeleteInvoice?invoiceId=${c?.invoiceId}`}
                                CallBack={true}
                                CallBackAPi={getInvoiceList}
                            />
                            <Link
                                className="add-log-btn ms-2"
                                onClick={() => handleSendEmail(c)}
                            >
                                Send Email
                            </Link>
                        </>
                    ),
                };
            });
            setInvoiceList(sdData);
        } else {
            setOpen(false);
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="invoice_list_page">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="product_box">
                                    <h4 className="all_title mb-0">Invoice List</h4>
                                    <div>
                                        <Link to={"create"} className="theme_btn">
                                            <BsPlusSquareDotted fontSize={24} className="me-3" />
                                            Create
                                        </Link>
                                    </div>
                                </div>
                                <div className="row mb-3">

                                    {/* <div className="ms-auto mt-auto col-md-4">
                                            <div className="form-group text-end filter-img">
                                                {open ? (
                                                    <Button className="theme_btn text-end">
                                                        Applying Filter{" "}
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="theme_btn text-end" type="submit">
                                                        <img
                                                            src={filter}
                                                            className="me-1"
                                                            alt=""
                                                            height={23}
                                                        />
                                                        Apply
                                                    </Button>
                                                )}
                                            </div>
                                        </div> */}
                                </div>
                                <ListTable
                                    headCells={InvoiceHead}
                                    action={true}
                                    ListData={invoiceList}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
};

export default InvoiceList;
