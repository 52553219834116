import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../../../Action/Action";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const CampaignHead = [
  { id: "campaignName", label: "Campaign Name" },
  { id: "type", label: "Type" },
  { id: "startDate", label: "Start Date" },
  { id: "noOfTemplates", label: "No of Template" },
  { id: "sendOnTime", label: "Notify Time" },
  { id: "status", label: "Status" },
  { id: "isActive", label: "IsActive" },
  { id: "createdOn", label: "Created On" },
];

function CampaignList() {
  const [open, setOpen] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const [campaignList, setCampaignList] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getCampaignList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const getCampaignList = async () => {
    setCampaignList([]);
    setOpen(true);
    let seData = {
      url: `/api/Campaign/GetCampaignList?schoolDistrictId=${LogDetail?.schoolDistrictId}`,
      headers: headers,
    };

    let resp = await GetCallApi(seData);
    setOpen(false);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        const sdData = resp.data.payload?.map((c) => {
          return {
            ...c,
            startDate: c?.startDate
              ? new Date(c?.startDate).toLocaleDateString()
              : "",
            createdOn: c?.createdOn
              ? new Date(c?.createdOn)?.toLocaleDateString()
              : "",
            isActive: c?.isActive === true ? "True" : "False",
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`edit/${c?.campaignId}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
                <DeletePopup
                  title={"Delete Campaign"}
                  text={c?.campaignName}
                  mtd={"post"}
                  url={`/api/Campaign/DeleteCampaignById?CampaignId=${c?.campaignId}`}
                />
              </>
            ),
          };
        });
        setCampaignList(sdData);
      } else {
        setOpen(false);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="campaign_listpage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <h4 className="all_title mb-0">Campaign List</h4>
                  <div className="d-flex">
                    <Link to={"create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <ListTable
                  headCells={CampaignHead}
                  action={true}
                  ListData={campaignList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CampaignList;
