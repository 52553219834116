import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { DeleteDataFor } from "../../../reducers/Common";
// import Axios from "../../../Utils/AxiosConfig";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { DeleteCallApi, PostCallApi } from "../../../Action/Action";

export default function DeletePopup(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const dispatch = useDispatch();
  // const token = useSelector((state) => state.login.LoginDetails.accessToken);
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const [derror, setDerror] = useState();

  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDerror({ err: "" });
  };
  const handledelete = async () => {
    setOpenCircle(true);
    setDerror({ err: "" });

    // let responce = await DeleteCallApi({ url: props.url, headers: headers });
    let responce;
    if (props?.mtd === "post") {
      responce = await PostCallApi({ url: props.url, body: {} });
    } else {
      responce = await DeleteCallApi({ url: props.url });
    }
    setOpenCircle(false);
    // Axios.delete()
    //   .then((responce) => {
    if (responce.status === 200) {
      if (responce.data.success) {
        if (props.CallBack) {
          props.CallBackAPi();
        }
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: responce.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        setOpenCircle(false);
        dispatch(DeleteDataFor(deleteInfo ? false : true));
        handleClose(false);
      } else {
        setDerror({ err: responce.data?.message });
        setOpenCircle(false);
      }
    }
    // })
    // .catch((error) => {
    //   setOpenCircle(false);
    //   console.log(error);
    // });
  };

  return (
    <>
      {props.showText ? (
        <Button type="button" onClick={handleOpen} className="delete_btn  ">
          Delete
        </Button>
      ) : (
        <button className="btn_dlt " onClick={handleOpen}>
          <MdDelete className="icon" fontSize={24} />
        </button>
      )}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}

      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              {/* <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => 9999 }}
                open={openCircle}
              >
                <CircularProgress sx={{ color: "#86c65a" }} />
              </Backdrop> */}
              <div className="popup_delete_detail_box">
                <h6>{props?.title}</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {props?.text} ?</span>
                  </p>
                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  <Button
                    className="popup_btn delete"
                    onClick={handledelete}
                    disabled={openCircle}
                  >
                    {openCircle ? (
                      <CircularProgress
                        sx={{
                          color: "#fff",
                          marginLeft: "0.5rem",
                          height: "32px !important",
                          width: "32px !important",
                        }}
                      />
                    ) : (
                      "Delete"
                    )}
                  </Button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
