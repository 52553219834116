import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "schoolCode", label: "Site Code" },
  { id: "schoolName", label: "Site Name" },
  { id: "districtName", label: "District Name" },
  { id: "servingLine", label: "Serving Line" },
  { id: "email", label: "Email" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "address", label: "Address" },
  { id: "city", label: "City" },
  // { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
];
function SiteManagement() {
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [openCircle, setOpenCircle] = useState(false);

  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    SettingsSiteData();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const SettingsSiteData = () => {
    setOpenCircle(true);
    Axios.get(`/api/School/GetSchoolList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                schoolCode: sd?.schoolCode,
                schoolName: sd?.schoolName,
                districtName: sd?.schoolDistrictName,
                servingLine: sd?.servingLine,
                email: sd?.email,
                phoneNumber: sd?.phoneNumber,
                address: sd?.address,
                city: sd?.city,
                state: sd?.state,
                zip: sd?.zip,
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.schoolId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Site"}
                      text={sd?.schoolName}
                      mtd={"post"}
                      url={`/api/School/DeleteSchool?schoolId=${sd?.schoolId}`}
                    />
                  </>
                ),
              };
            });
            setPrdList(SdData);
          } else {
            setOpenCircle(true);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {/* <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
      </div> */}
        <section className="site_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <h4 className="all_title mb-0">Site List</h4>
                  <div className="d-flex">
                    {/* <Link to={""} className="cancel_btn me-4">
                  Download
                </Link> */}
                    <Link to={"create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SiteManagement;
