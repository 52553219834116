import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import ImgPopup from "../../../../Components/Reusable/ImgPopup";
import { Config } from "../../../../Utils/Config";
import defaultProfile from "../../../../Assets/default_profile.jpg";

const ListTable = lazy(() =>
  import("../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "logo", label: "Logo" },
  { id: "mealTitle", label: "POS Items Title" },
  { id: "mealType", label: "POS Items Type" },
  { id: "districtName", label: "District Name" },
  // { id: "day", label: "Day" },
  { id: "mealPrice", label: "POS Items Price" },
  { id: "points", label: "POS Items Points" },
  { id: "isReimbursableMeal", label: "Is Reimbursable POS Items" },
];
function MealsList() {
  const [categoyList, setCategoyList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [overlayList, setOverlayList] = useState([]);
  const [overlaySelect, setOverlaySelect] = useState();

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getOverListData();
    // eslint-disable-next-line
  }, [deleteInfo]);
  useEffect(() => {
    if (overlaySelect) {
      mealListData(overlaySelect);
    }
    // eslint-disable-next-line
  }, [overlaySelect]);

  const mealListData = (meal) => {
    setOpenCircle(true);
    Axios.get(
      `/api/Meals/GetAllMealsData?overLayId=${meal}&schoolDistrictId=${LogDetail?.schoolDistrictId}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                logo: (
                  <ImgPopup
                    img={
                      sd?.imageUrl
                        ? `${Config.API_HOST_URL_live}${sd?.imageUrl}`
                        : defaultProfile
                    }
                  />
                ),
                mealTitle: sd?.mealTitle,
                districtName: sd?.schoolDistrictName,
                mealType: sd?.mealType,
                day: sd?.nameOfDay,
                isReimbursableMeal:
                  sd?.isReimbursableMeal === true ? "Yes" : "No",
                // mealPrice: (Math.round(sd?.mealPrice * 100) / 100).toFixed(2),
                mealPrice: sd?.mealPrice?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                points: sd?.points ? sd?.points : 0,
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.mealId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Meal"}
                      text={sd?.mealTitle}
                      mtd={"post"}
                      url={`/api/Meals/DeleteMealsData?mealId=${sd?.mealId}`}
                    />
                  </>
                ),
              };
            });
            setCategoyList(SdData);
          }
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const getOverListData = () => {
    Axios.get(`/api/OverLay/GetOverLayList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                value: sd?.overlayId,
                label: sd?.overlayName,
              };
            });
            setOverlayList(SdData);
            setOverlaySelect(SdData[0]?.value);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="meals_list ">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">POS Items</div>
                  <Link to={"create"} className="theme_btn">
                    <BsPlusSquareDotted fontSize={24} className="me-3" />
                    Create
                  </Link>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={categoyList}
                  dropdown={true}
                  dropValue={overlaySelect}
                  setDropValue={setOverlaySelect}
                  dropList={overlayList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default MealsList;
