import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../../Action/Action";
import ImgPopup from "../../../../Components/Reusable/ImgPopup";
import { Config } from "../../../../Utils/Config";
import defaultProfile from "../../../../Assets/default_profile.jpg";
import ToggleButton from "../../../../Components/Reusable/ToggleButton";

const ListTable = lazy(() =>
    import("../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const surveyHead = [
    { id: "logo", label: "Survery Image" },
    { id: "surveryTemplateType", label: "Survery Type" },
    { id: "surveyTemplateDistrict", label: "Survey District", widthFix: true },
    { id: "language", label: "Language" },
    { id: "createdOn", label: "Created On" },
];

function SurveyFormList() {
    const [open, setOpen] = useState(false);
    const [surveyList, setSurveyList] = useState([]);

    let deleteInfo = useSelector((state) => state.Common?.DeleteData);

    useEffect(() => {
        getSurveyFormList();
        // eslint-disable-next-line
    }, [deleteInfo]);

    function convert(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    const getSurveyFormList = async () => {
        setSurveyList([]);
        setOpen(true);
        let seData = {
            url: `/api/SurveyTemplate/GetAllSurveyTemplatesList`,
        };

        let resp = await GetCallApi(seData);
        setOpen(false);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                const sdData = resp.data.payload?.map((x) => {
                    return {
                        ...x,
                        surveyTemplateDistrict: x?.surveyTemplateDistrict,
                        createdOn: convert(x?.createdOn?.split("T")[0]),
                        surveryTemplateType: x?.surveryTemplateType,
                        logo: (
                            <ImgPopup
                                img={
                                    x?.logo
                                        ? `${Config.API_HOST_URL_live}${x?.logo}`
                                        : defaultProfile
                                }
                            />
                        ),
                        Action: (
                            <>
                                {x?.isActive && (
                                    <Link
                                        type="button"
                                        className="btn_edit me-2"
                                        to={`edit/${x?.surveyTemplateId}`}
                                    >
                                        <MdEdit fontSize={24} />
                                    </Link>
                                )}
                                <ToggleButton
                                    checked={x?.isActive === true ? "1" : "0"}
                                    title={"Delete Survey"}
                                    text={x?.surveryTemplateType}
                                    mtd={"post"}
                                    url={`/api/SurveyTemplate/DeleteSurveyTemplates?surveyTemplateId=${x?.surveyTemplateId}`}
                                />
                            </>
                        ),
                    };
                });
                setSurveyList(sdData ?? []);
            } else {
                setOpen(false);
            }
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="survey_form_view_listpage">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="product_box">
                                    <div className="all_title ProductTitle">Survey Guideline List</div>
                                    <Link to={"create"} className="theme_btn">
                                        <BsPlusSquareDotted fontSize={24} className="me-3" />
                                        Create
                                    </Link>
                                </div>
                                <ListTable
                                    headCells={surveyHead}
                                    action={true}
                                    ListData={surveyList}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default SurveyFormList;
